import axios from "axios";
import authHeader from "./auth-header";
import UserService from "./user.service";

const API_URL = process.env.REACT_APP_API_URL;

const API_JWT_CREATE = "/auth/jwt/create/";
const API_USER_REFRESH_TOKEN = "/auth/jwt/refresh/";
const API_USER_VERIFY_ACCESS_TOKEN = "/auth/jwt/verify/";

const API_REQUEST_BETA_ACCESS = "/auth/users/";
const API_USER_ACTIVATE = "/auth/users/activation/";
const API_USER_DEACTIVATE = "/users/deactivation/";

const API_ADMIN_ACTIVATE = "/users/admin-activation/";
const API_ADMIN_DEACTIVATE = "/users/admin-deactivation/";

const API_INVOICE_ACTIVATE = "/users/invoice-activation/";
const API_INVOICE_DEACTIVATE = "/users/invoice-deactivation/";

const API_USER_DELETE = "/users/user-deletion/";

const API_USER_UPDATE = "/users/update/";
const API_USER_RESET_PASSWORD_CONFIRM = "/auth/users/reset_password_confirm/";
const API_USER_REQUEST_RESET_PASSWORD = "/auth/users/reset_password/";
const API_GET_MY_DATA = "/auth/users/me/";

const API_USER_LIST = "/users/list/";
const API_USER_GET_ACTIVATION_TOKEN = "/users/activation-data/";

const FEEDBACK = "/feedback/";
const NEWS = "/news/";
const NEWSANSWER = "/newsanswer/";

const DUMMY_PASSWORD = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

const register = (name, email, company_name, company_address, billing_address, chosenRateId, coupon) => {
    return axios.post(API_URL + API_REQUEST_BETA_ACCESS, {
        email: email,
        name: name,
        company_name: company_name,
        company_address: company_address,
        billing_address: billing_address,
        rate_id: chosenRateId,
        password: DUMMY_PASSWORD,
        re_password: DUMMY_PASSWORD,
        coupon: coupon,
    });
};

const passwordResetConfirmation = (data) => {
    return axios
        .post(API_URL + API_USER_RESET_PASSWORD_CONFIRM, data);
};

const requirePasswordResetLink = (email) => {
    console.log("requiring password reset link for:");
    console.log(email);
    let token = getCurrentUser();

    return axios
        .post(API_URL + API_USER_REQUEST_RESET_PASSWORD, {
                email: email
            },
            {
                headers: {
                    'Authorization': 'JWT ' + token.access,
                }
            });
};

const requirePasswordResetLinkBeforeLogin = (email) => {
    console.log("requiring password reset link for:");
    console.log(email);
    let token = getCurrentUser();

    return axios
        .post(API_URL + API_USER_REQUEST_RESET_PASSWORD, {
                email: email
            });
};

const login = async (email, password) => {
    return axios
        .post(API_URL + API_JWT_CREATE, {
            email: email,
            password: password,
        })
        .then(async (response) => {
            console.log("Token:");
            console.log(response.data);
            // response.data.newsText = "blah";
            // response.data.lastFeedbackDate = "2023-03-30";
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const getUserList = () => {
    let token = getCurrentUser();
    return axios.get(API_URL + API_USER_LIST, {
        headers: {
            'Authorization': 'JWT ' + token.access,
        }
    });
}

const getUidAndTokenToActivateUser = (id) => {
    let token = getCurrentUser();
    return axios
        .get(API_URL + API_USER_GET_ACTIVATION_TOKEN, {
            headers: {
                'Authorization': 'JWT ' + token.access,
            },
            params: {pk: id}
        });
}

const activateUser = (params) => {
    return axios
        .post(API_URL + API_USER_ACTIVATE, params);
}

const deActivateUser = (params) => {
    let token = getCurrentUser();
    return axios
        .post(API_URL + API_USER_DEACTIVATE, params,
            {
                headers: {
                    'Authorization': 'JWT ' + token.access,
                }
            }
        );
}

const activateAdmin = (params) => {
    return axios
        .post(API_URL + API_ADMIN_ACTIVATE, params);
}

const deActivateAdmin = (params) => {
    let token = getCurrentUser();
    return axios
        .post(API_URL + API_ADMIN_DEACTIVATE, params,
            {
                headers: {
                    'Authorization': 'JWT ' + token.access,
                }
            }
        );
}

const deleteUser = (params) => {
    let token = getCurrentUser();
    return axios
        .post(API_URL + API_USER_DELETE, params, {
            headers: {
                'Authorization': 'JWT ' + token.access,
            },
        });
}

const activateInvoice = (params) => {
    return axios
        .post(API_URL + API_INVOICE_ACTIVATE, params);
}

const deActivateInvoice = (params) => {
    let token = getCurrentUser();
    return axios
        .post(API_URL + API_INVOICE_DEACTIVATE, params,
            {
                headers: {
                    'Authorization': 'JWT ' + token.access,
                }
            }
        );
}

const getCurrentUserData = () => {
    let token = getCurrentUser();
    return axios.get(API_URL + API_GET_MY_DATA, {
        headers: {
            'Authorization': 'JWT ' + token.access,
        }
    });
}

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const refreshAccessToken = () => {
    let oldToken = authHeader();
    return axios.post(API_URL + API_USER_REFRESH_TOKEN, authHeader())
        .then((response) => {
            // console.log("New access token:");
            // console.log(response.data);
            let newToken = {
                access: response.data.access,
                refresh: oldToken.refresh
            }

            localStorage.removeItem("user");
            UserService.setToken(newToken);
        });
};

const isAccessTokenStillAlive = () => {
    let token = getCurrentUser();
    if (!token) {
        console.log("!token")
        return false;
    }
    // console.log("access_token " + token.access);
    return axios
        .post(API_URL + API_USER_VERIFY_ACCESS_TOKEN,
            {
                "token": token.access
            });
}

const checkForNewNews = () => {
    let token = getCurrentUser();
    return axios.get(API_URL + NEWS + '?unread=1', {
        headers: {
            'Authorization': 'JWT ' + token.access,
        },
    })
        .then((response) => {
            return response.data;
        });
};

const checkForLastFeedbackDate = () => {
    let token = getCurrentUser();
    return axios.get(API_URL + FEEDBACK, {
        headers: {
            'Authorization': 'JWT ' + token.access,
        },
    })
        .then((response) => {
            return response.data;
        });
};

const createFeedback = (chosenSmile, feedbackText) => {
    console.log(chosenSmile + " " + feedbackText);
    let token = getCurrentUser();
    return axios.post(API_URL + FEEDBACK, {
            "feedback": chosenSmile,
            "text": feedbackText
        },
        {
            headers: {
                'Authorization': 'JWT ' + token.access,
            }
        }
    );
};

const setNewsAnswer = (newsId, newsAnswer) => {
    console.log(newsId + " " + newsAnswer);
    let token = getCurrentUser();
    return axios.post(API_URL + NEWSANSWER, {
            "newsId": newsId,
            "newsAnswer": newsAnswer
        },
        {
            headers: {
                'Authorization': 'JWT ' + token.access,
            }
        }
    );
};

const updateUser = (params) => {
    let token = getCurrentUser();
    return axios
        .post(API_URL + API_USER_UPDATE,
            {
                id: params.id,
                email: params.email,
                name: params.name,
                company_name: params.company_name,
                company_address: params.company_address,
                billing_address: params.billing_address,
            },
            {
                headers: {
                    'Authorization': 'JWT ' + token.access,
                }
            }
        );
}

export default {
    register,
    requirePasswordResetLink,
    requirePasswordResetLinkBeforeLogin,
    passwordResetConfirmation,
    login,
    logout,
    getCurrentUser,
    getCurrentUserData,
    getUserList,
    getUidAndTokenToActivateUser,
    activateUser,
    deActivateUser,
    activateAdmin,
    deActivateAdmin,
    activateInvoice,
    deActivateInvoice,
    refreshAccessToken,
    isAccessTokenStillAlive,
    createFeedback,
    checkForNewNews,
    setNewsAnswer,
    checkForLastFeedbackDate,
    updateUser,
    deleteUser
};