export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.access && user.refresh) {
    return {
      access: user.access,
      refresh: user.refresh,
    };
  } else {
    return false;
  }
}