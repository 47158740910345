import React from "react";
import Moment from 'moment';
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from "react";

import AuthService from "../services/auth.service";
import BookmarkService from "../services/bookmark.service";
import ErrorHandler from "../services/error.handler";

import SuperLoader from "../components/SuperLoader";
import RichObjectTreeView from "../components/NestedTreeView";
import TileMasonryRounded from "../components/TileRounden";
import BasicButton from "../components/buttons/BasicButton";

import Alert from "@mui/material/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import TableCell from "@mui/material/TableCell";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Masonry from "@mui/lab/Masonry";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from '@mui/material/TextField';

import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoneIcon from "@mui/icons-material/Done";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {DialogContent} from "@mui/material";
import { MenuItem, FormControl, Select, InputLabel  } from '@mui/material';
import { countries } from '../constants/countries';

const Bookmarks = (props) => {

    const style = {
        backgroundColor: "white",
        margin: "50px auto",
        maxWidth: "95%",
        boxShadow: 24,
        p: 4,
        border: "0"
    };

    const {t} = useTranslation();
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [docModalData, setDocModalData] = useState([]);
    const [treeData, setTreeData] = useState();

    const [loading, setLoading] = useState(false);

    const [bookmarkList, setBookmarkList] = useState([]);
    const [saveBookmark, setSaveBookmark] = useState(false);
    const [taxNumberToBeDeletedFromBookmarks, setTaxNumberToBeDeletedFromBookmarks] = useState(undefined);
    const [taxNumber, setTaxNumber] = useState("");
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [destinationCountry, setDestinationCountry] = useState(''); 
    
    const navigate = useNavigate();

    useEffect(() => {
        loadBookmarkList();
    }, []);

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
    };

    const handleExportModalClose = () => {
        setIsExportModalOpen(false);
        setTaxNumber("");
        setDestinationCountry("");
    }


    const loadBookmarkList = async () => {
        setLoading(true);
        AuthService.isAccessTokenStillAlive()
            .then(function (response) {
                // console.log(response);
                BookmarkService.bookmarkList()
                    .then(function (response) {
                        // console.log(response.data.bookmarks);
                        setBookmarkList(response?.data.bookmarks);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error.response.status + error.response.data.code);
                        setMessage('Error: ' + error.message);
                        setLoading(false);
                    })
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    // console.log(error.response.headers);
                    console.log("Access token has expired. Refreshing...");
                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                        AuthService.refreshAccessToken()
                            .then(() => {
                                loadBookmarkList();
                            })
                            .catch((error) => {
                                // expired refresh token => logout
                                ErrorHandler.handleError(error);
                                console.log("Refresh token has expired. Logout...");
                                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                    AuthService.logout();
                                    // navigate('/');
                                    window.location.reload();
                                }
                            });
                    } else if (error.response.status === 403) {
                        navigate('/');
                        window.location.reload();
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    setMessage('Error: ' + error.message);
                    setLoading(false);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    setMessage('Error' + error.message);
                    setLoading(false);
                }
                console.log(error.config);
            });
    };

    const bookmarkAdd = (tax_number) => {
        BookmarkService.bookmarkCreate(tax_number)
            .then((response) => {
                console.log(response.status);
                if (response.status === 202) {
                    console.log(response.data.message);
                }
                loadBookmarkList();
            })
            .catch((error) => {
                // expired access token
                console.log(error);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            bookmarkDelete(tax_number);
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
    }

    const bookmarkDelete = (tax_number) => {

        setSaveBookmark(false);
        BookmarkService.bookmarkDelete(tax_number)
            .then((response) => {
                console.log(response.status);
                if (response.status === 202) {
                    console.log(response.data.message);
                }
            })
            .catch((error) => {
                // expired access token
                console.log(error);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            bookmarkDelete(tax_number);
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
        loadBookmarkList();
    }

    const handleClose = () => {
        setIsModalOpen(false)
        setTreeData(null);
        setDocModalData([]);
    }

    const openCloseTreeView = (index) => {
        setTreeData(bookmarkList[index].tree_path);
        setIsModalOpen(isModalOpen => !isModalOpen);
    }

    const openCloseDocView = (data) => {
        console.log(data.vzta_documents);
        setDocModalData(data.vzta_documents);
        setIsModalOpen(isModalOpen => !isModalOpen);
    }

    const renderTable = () => {
        return (
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('approvalNumber', "Genehmigungsnummer")}</TableCell>
                        <TableCell>{t('description', "Beschreibung")}</TableCell>
                        <TableCell align="right">{t('validFrom', "Gültig ab")}</TableCell>
                        <TableCell align="right">{t('validTill', "Gültig bis")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {docModalData.map((row) => (
                        <TableRow
                            // key={row.name}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>{row.genehmigungsnummer}</TableCell>
                            <TableCell>
                                {row.description}
                            </TableCell>
                            <TableCell align="right">{Moment(row.valid_start).format('D.MM.YYYY')}</TableCell>
                            <TableCell align="right">{Moment(row.valid_end).format('D.MM.YYYY')}</TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        )
    }

    const ModalWindow = () => {
        return (
            <Modal
                sx={{
                    overflow: "scroll",
                    minHeight: "600px",
                    maxHeight: "100%",
                }}
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={style}
                >
                    <CloseIcon sx={{float: "right", cursor: "pointer"}} onClick={handleClose}/>
                    {docModalData.length > 0 &&
                    renderTable()
                    }
                    {treeData &&
                    <RichObjectTreeView
                        treeData={treeData}
                    />
                    }
                </Box>
            </Modal>
        )
    }

    const openBookmarkDeleteDialog = (tax_number) => {
        setTaxNumberToBeDeletedFromBookmarks(tax_number);
        setSaveBookmark(true);
    }

    const handleBookmarkDeleteModalClose = () => {
        setSaveBookmark(false);
    }

    const handleForeignCountryChange = (event) => {
        setDestinationCountry(event.target.value);
    };

    const linkToAccessToMarketPage = () => {
        const origin = 'DE';
        const destination = destinationCountry;
        const url = `https://trade.ec.europa.eu/access-to-markets/de/search?product=${taxNumber}&origin=${origin}&destination=${destination}`;

        window.open(url, '_blank');
    };

    // const bookmarkNameChange = (e) => {
    //     setBookmarkName(e.target.value);
    // }

    const ExportControlModalWindow = () => {
        return (
            <Dialog
                open={isExportModalOpen}
                onClose={handleExportModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography sx={{
                        color: "#02615D",
                    }}
                        variant="h5"
                        component="div"
                        textAlign={"center"}>
                        {t("exportControl", "Export Kontrolle")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        disabled
                        value={taxNumber}
                        // onChange={bookmarkNameChange}
                        label="Zolltariffnummer"
                        margin="normal"
                    />
                </DialogContent>
                <DialogContent>
                <Box sx={{ display: 'flex'}}>
                    <TextField
                        disabled
                        value="Deutschland"
                        label="Von"
                        style={{ marginRight: 40 }}
                        fullWidth
                    />
                    <FormControl fullWidth >
                        <InputLabel>Nach</InputLabel>
                        <Select
                            value={destinationCountry}
                            onChange={handleForeignCountryChange}
                            label="Nach"
                        >
                            {countries.map((country) => (
                                <MenuItem key={country.code} value={country.code}>
                                    {country.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        paddingRight: "20px",
                        paddingBottom: "0px"
                    }}>
                    <BasicButton
                        onClick={linkToAccessToMarketPage}
                        size="medium"
                        text={t("showResults", "Ergebnisse anzeigen")}
                    />
                </DialogActions>
            </Dialog>
        )
    }
    const openCloseExportControlView = (data) => {
        setIsExportModalOpen(isExportModalOpen => !isExportModalOpen);
        setTaxNumber(data.tax_number);
    }

    return (
        <span>
            {loading && <SuperLoader/>}
            {!loading &&
            <>
                <ModalWindow/>
                <ExportControlModalWindow/>
                <Dialog
                    open={saveBookmark}
                    onClose={handleBookmarkDeleteModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogTitle id="alert-dialog-title">
                        <Typography sx={{
                            color: "#02615D",
                        }}
                                    variant="h5"
                                    component="div"
                                    >
                            <WarningAmberIcon
                                sx={{
                                    marginBottom: "-5px",
                                    fontSize: "27px",
                                }}
                            /> {t("deleteBookmarkModal", "Wollen Sie die ZTN wirklich von der Merkliste entfernen?")}

                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography sx={{
                            color: "#02615D",
                        }}
                                    variant="h6"
                                    component="div">
                            {taxNumberToBeDeletedFromBookmarks}
                        </Typography>

                    </DialogContent>

                    <DialogActions
                        sx={{
                            paddingRight: "10px",
                            paddingBottom: "0px"
                        }}>
                        <BasicButton
                            startIcon={<HighlightOffIcon/>}
                            onClick={handleBookmarkDeleteModalClose}
                            size="small"
                            type="close"
                            text={t("cancel", "Abbrechen")}
                        />
                        <BasicButton
                            startIcon={<DoneIcon/>}
                            onClick={() => bookmarkDelete(taxNumberToBeDeletedFromBookmarks)}
                            size="small"
                            text={t("deleteBookmark", "zur Merkliste hinzufügen")}
                        />
                    </DialogActions>
                </Dialog>
                <Container
                    maxWidth="xl"
                    component="main">
                    <CssBaseline/>

                    <Typography sx={{
                        mt: 3.4,
                        mb: 3,
                        fontWeight: "bold",
                        color: "#02615D",
                    }}
                                variant="h5"
                                component="div"
                                textAlign={"center"}>
                        <StarOutlineIcon
                            sx={{
                                marginBottom: "-5px",
                                fontSize: "30px",
                            }}
                        /> {t('bookmarks', "Merkliste")}
                    </Typography>

                    {message && (
                        <Stack sx={{width: '100%', marginTop: 4.4}} spacing={2}>
                            <Alert
                                onClose={handleCloseMessage}
                                severity={severity}>{message}</Alert>
                        </Stack>
                    )}
                    {bookmarkList &&
                    <Box sx={{flexGrow: 1}}>
                        <Masonry columns={3} spacing={3}
                                 sx={{margin: 0, marginTop: 3}}
                        >
                            {bookmarkList.map((value, index) =>
                                <TileMasonryRounded
                                    data={value}
                                    openCloseTreeView={() => openCloseTreeView(index)}
                                    openCloseDocView={() => openCloseDocView(value)}
                                    isBookmark={true}
                                    bookmarkAdd={() => bookmarkAdd(value.tax_number)}
                                    bookmarkDelete={() => openBookmarkDeleteDialog(value.tax_number)}
                                    openCloseExportControlView={() => openCloseExportControlView(value)}
                                />)}
                        </Masonry>
                    </Box>
                    }
                </Container>
            </>
            }
        </span>
    );
};

export default Bookmarks;
