import * as React from 'react';
import {useEffect, useState} from "react";
import {Routes, Route, Link, useNavigate} from "react-router-dom"
import {useTranslation} from 'react-i18next'

import authHeader from "./services/auth-header";
import AuthService from "./services/auth.service";
import RateService from "./services/rate.service";

import { backgroundMain } from './components/Colors';

import BetaAccessRequest from "./pages/betaAccessRequest"
import Bookmarks from "./pages/bookmarks.js"
import Healthcheck from "./pages/healthcheck";
import NewsBox from './pages/newsbox';
import Statistics from './pages/statistics';
import Coupon from './pages/coupon';
import History from "./pages/history";
import Impressum from "./pages/impressum";
import PasswordReset from "./pages/admin/PasswordReset";
import PasswordResetRequest from "./pages/admin/PasswordResetRequest";
import Plan from "./pages/Plan";
import Invoices from "./pages/Invoices";
import Profile from "./pages/profile";
import SignIn from "./pages/signin";
import Search from "./pages/search";
import Support from "./pages/support";
import SearchHistory from "./pages/admin/searchHistory";
import SupportList from "./pages/admin/SupportList";
import UserList from "./pages/admin/userList";
import BasicButton from "./components/buttons/BasicButton";

import Menu from '@mui/material/Menu';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from '@mui/icons-material/Search';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ListItem from "@mui/material/ListItem";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LoginIcon from "@mui/icons-material/Login";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PercentIcon from '@mui/icons-material/Percent';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

function Application() {

    const {t} = useTranslation();
    const [loggedIn, setLoggedIn] = useState(true);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [me, setMe] = useState(
        {
            name: "",
            email: undefined,
            is_admin: false,
            is_active: false
        }
    );
    const [myRateName, setMyRateName] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        checkIfUserStillLoggedIn();
    }, []);

    const checkIfUserStillLoggedIn = () => {
        if (authHeader()) {
            // console.log(authHeader());
            AuthService.getCurrentUserData()
                .then(function (response) {
                    RateService.invoiceList()
                        .then(function (response) {
                            if (typeof(response.data[0].rateuser) != 'undefined' && response.data[0].rateuser.is_paid) {
                                console.log("My rate:");
                                console.log(response.data[0].rateuser.rate.name);
                                setMyRateName(response.data[0].rateuser.rate.name);
                            }
                        })
                    setMe(response.data);
                    setLoggedIn(true);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error.response.status);
                    console.log(error.response.data.code);
                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                        AuthService.refreshAccessToken()
                            .then(() => {
                                checkIfUserStillLoggedIn();
                            })
                            .catch((error) => {
                                // expired refresh token => logout
                                console.log(error.response.status);
                                console.log(error.response.data.code);
                                console.log("Refresh token has expired. Logout...");
                                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                    AuthService.logout();
                                    // navigate('/');
                                    window.location.reload();
                                }
                            });
                    }
                })
                .then(function () {
                    // always executed
                    // todo
                });
        } else setLoggedIn(false);
    }

    const handleOpenNavMenu = (event) => {
        checkIfUserStillLoggedIn();
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        checkIfUserStillLoggedIn();
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        // checkIfUserStillLoggedIn();
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        checkIfUserStillLoggedIn();
    };

    const clickLogout = () => {
        checkIfUserStillLoggedIn();
        console.log("authHeader:");
        console.log(authHeader());

        AuthService.logout();
        navigate('/');
        window.location.reload();
    }

    return (
        <div className="App"
        style={{
            background: backgroundMain,
            height: "100vh",
            transition: "all 0.5s ease"
        }}
        >
            <AppBar
                style={{background: '#f0eef1'}} //color
                position="static">
                <Container maxWidth="xxl">
                    <Toolbar disableGutters>
                        <img
                            onClick={() => {
                                navigate('/');
                                // window.location.reload();
                            }}
                            src={"../../images/custify-logo-left-pink.png"} alt="Custify" height={"50px"}
                            style={{
                                // paddingTop: "4px",
                                cursor: "pointer",
                            }}
                        />
                        {loggedIn &&
                        // collapsed menu
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                sx={{color: '#02615D'}}
                                size="large"
                                aria-label=""
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            {/*Collapsed menu*/}
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                <MenuItem key="search" onClick={handleCloseNavMenu}>
                                    <Link
                                        to="search">
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}
                                            color={"#02615D"}
                                            textAlign="center"><SearchIcon/>&nbsp;{t('search')}</Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem key="bookmarks" onClick={handleCloseNavMenu}>
                                    <Link
                                        to="bookmarks">
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}
                                            color={"#02615D"}
                                            textAlign="center"><StarOutlineIcon/>&nbsp;{t('bookmarks')}</Typography>
                                    </Link>
                                </MenuItem>
                                {!me.is_admin &&
                                <MenuItem key="history" onClick={handleCloseNavMenu}>
                                    <Link
                                        onClick={() => {
                                            window.location.href = "/history"
                                        }}
                                        to="history">
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}
                                            color={"#02615D"}
                                            textAlign="center"><ManageSearchIcon/>&nbsp;{t('history')}</Typography>
                                    </Link>
                                </MenuItem>
                                }
                                {/*{!me.is_admin &&*/}
                                {/*<MenuItem key="support" onClick={handleCloseNavMenu}>*/}
                                {/*    <Link*/}
                                {/*        to="support">*/}
                                {/*        <Typography*/}
                                {/*            style={{*/}
                                {/*                display: 'flex',*/}
                                {/*                alignItems: 'center',*/}
                                {/*                flexWrap: 'wrap',*/}
                                {/*            }}*/}
                                {/*            color={"#02615D"}*/}
                                {/*            textAlign="center"><SupportAgentIcon/>&nbsp;{t('support')}</Typography>*/}
                                {/*    </Link>*/}
                                {/*</MenuItem>*/}
                                {/*}*/}

                                {me.is_admin &&
                                <MenuItem key="userlist" onClick={handleCloseNavMenu}>
                                    <Link
                                        to="userlist">
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}
                                            color={"#02615D"}
                                            textAlign="center"><PeopleOutlineIcon/>&nbsp;{t('userList')}</Typography>
                                    </Link>
                                </MenuItem>
                                }
                            </Menu>
                        </Box>
                            // collapsed menu end
                        }

                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, flexDirection: 'row-reverse'}}>
                            {loggedIn && !me.is_admin &&
                            <Link
                                onClick={() => {
                                    window.location.href = "/history"
                                }}
                                to="history">
                                <Button
                                    sx={{
                                        marginLeft: "20px",
                                        color: "#02615D",
                                    }}
                                    color="inherit"><ManageSearchIcon/>{t('history')}
                                </Button>
                            </Link>
                            }
                            {loggedIn && me.is_admin &&
                            <Link
                                to="userlist">
                                <Button
                                    sx={{
                                        marginLeft: "20px",
                                        color: "#02615D",
                                    }}
                                    color="inherit"><PeopleOutlineIcon/>&nbsp; {t('userList')}
                                    {loggedIn}
                                </Button>
                            </Link>
                            }
                            {loggedIn &&
                            <Link
                                to="bookmarks">
                                <Button
                                    sx={{
                                        marginLeft: "20px",
                                        color: "#02615D",
                                    }}
                                    color="inherit"><StarOutlineIcon/>&nbsp;{t('bookmarks')}
                                    {loggedIn}
                                </Button>
                            </Link>
                            }
                            {loggedIn &&
                            <Link
                                to="search">
                                <Button
                                    sx={{
                                        marginLeft: "20px",
                                        color: "#02615D",
                                    }}
                                    color="inherit"><SearchIcon/> {t('search')}
                                    {loggedIn}
                                </Button>
                            </Link>
                            }
                        </Box>

                        <Box sx={{flexGrow: 0}}>
                            {!loggedIn &&
                            <Link to="signin">
                                <BasicButton
                                    noMargin={true}
                                    startIcon={<LoginIcon/>}
                                    text={t('login')}
                                    type={"justText"}
                                />
                                &nbsp;&nbsp;
                            </Link>
                            }
                            {!loggedIn &&
                            <Link
                                component="button"
                                underline="none"
                                to="betaaccessrequest"
                            >
                                <BasicButton
                                    uppercase
                                    variant="contained"
                                    startIcon={<PersonAddAltIcon/>}
                                    text={t('BetaAccessRequestButton', 'Konto erstellen')}
                                    noMargin={true}
                                />
                            </Link>
                            }
                            {loggedIn &&
                            <>
                                <ListItem
                                    onClick={handleOpenUserMenu}>
                                    <ListItemAvatar
                                        sx={{cursor: "pointer"}}>
                                        <Avatar
                                            sx={{color: "#02615D"}}
                                        >
                                            {/*<Tooltip title={t('openSettings')}>*/}
                                            <PersonOutlineIcon/>
                                            {/*</Tooltip>*/}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{cursor: "pointer", color: "#02615D"}}
                                        primary={me.email}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{display: 'inline', color: "#02615D"}}
                                                    component="span"
                                                    variant="body2"
                                                    color="white"
                                                >
                                                    {me.is_admin &&
                                                    <strong
                                                    >{t('admin')}: </strong>
                                                    }
                                                    {me.name}
                                                    {myRateName &&
                                                        <strong> - {myRateName}</strong>
                                                    }
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </>
                            }

                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                onClose={handleCloseUserMenu}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                            >

                                <MenuItem key="profile" onClick={handleCloseNavMenu}>
                                    <Link
                                        to="profile">
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}
                                            color={"#02615D"}
                                            textAlign="center"><AssignmentIndIcon/>&nbsp;{t('profile')}</Typography>
                                    </Link>
                                </MenuItem>

                                {/*{me.is_admin &&*/}
                                {/*<MenuItem key="supportlist" onClick={handleCloseUserMenu}>*/}
                                {/*    <Link*/}
                                {/*        to="supportlist">*/}
                                {/*        <Typography*/}
                                {/*            style={{*/}
                                {/*                display: 'flex',*/}
                                {/*                alignItems: 'center',*/}
                                {/*                flexWrap: 'wrap',*/}
                                {/*                color: "#02615D"*/}
                                {/*            }}*/}
                                {/*            color={"black"}*/}
                                {/*            textAlign="center"><SupportAgentIcon/>&nbsp;{t('supportlist')}</Typography>*/}
                                {/*    </Link>*/}
                                {/*</MenuItem>*/}
                                {/*}*/}
                                {me.is_admin &&
                                <>
                                    <MenuItem key="searchhistory" onClick={handleCloseUserMenu}>
                                        <Link
                                            onClick={() => {
                                                window.location.href = "/history"
                                            }}
                                            to="searchhistory">
                                            <Typography
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                    color: "#02615D"
                                                }}
                                                color={"black"}
                                                textAlign="center"><ManageSearchIcon/>&nbsp;{t('searchHistory')}
                                            </Typography>
                                        </Link>
                                    </MenuItem>
                                    <Divider/>
                                </>
                                }
                                <MenuItem key="rateplan" onClick={handleCloseUserMenu}>
                                    <Link
                                        to="plan">
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                color: "#02615D"
                                            }}
                                            color={"black"}
                                            textAlign="center"><AccountBalanceWalletIcon/>&nbsp;{t('rateplan', "Preise")}
                                        </Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem key="invoices" onClick={handleCloseUserMenu}>
                                    <Link
                                        to="invoices">
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                color: "#02615D"
                                            }}
                                            color={"black"}
                                            textAlign="center"><CorporateFareIcon/>&nbsp;{t('myInvoices', "Rechnungen")}
                                        </Typography>
                                    </Link>
                                </MenuItem>
                                {
                                    me.is_admin && <MenuItem key="newsfeed" onClick={handleCloseUserMenu}>
                                    <Link
                                        to="newsbox">
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                color: "#02615D"
                                            }}
                                            color={"black"}
                                            textAlign="center"><NotificationsNoneIcon/>&nbsp;{t('newsBox', "Nachrichtenbox")}
                                        </Typography>
                                    </Link>
                                </MenuItem>
                                }
                                  {
                                    me.is_admin && <MenuItem key="userstats" onClick={handleCloseUserMenu}>
                                    <Link
                                        to="statistics">
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                color: "#02615D"
                                            }}
                                            color={"black"}
                                            textAlign="center"><SignalCellularAltIcon/>&nbsp;{t('userStatistics', "Benutzerstatistiken")}
                                        </Typography>
                                    </Link>
                                </MenuItem>
                                }

                                {/*{*/}
                                {/*    me.is_admin && <MenuItem key="coupon" onClick={handleCloseUserMenu}>*/}
                                {/*        <Link*/}
                                {/*            to="coupon">*/}
                                {/*            <Typography*/}
                                {/*                style={{*/}
                                {/*                    display: 'flex',*/}
                                {/*                    alignItems: 'center',*/}
                                {/*                    flexWrap: 'wrap',*/}
                                {/*                    color: "#02615D"*/}
                                {/*                }}*/}
                                {/*                color={"black"}*/}
                                {/*                textAlign="center"><PercentIcon/>&nbsp;{t('coupon', "Gutschein")}*/}
                                {/*            </Typography>*/}
                                {/*        </Link>*/}
                                {/*    </MenuItem>*/}
                                {/*}*/}
                                
                                <Divider/>
                                <MenuItem key="logout" onClick={handleCloseUserMenu}>
                                    <Link
                                        to="logout">
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                                color: "#02615D"
                                            }}
                                            onClick={clickLogout}
                                            color={"black"}
                                            textAlign="center"><LogoutIcon/>&nbsp;{t('logout')}</Typography>
                                    </Link>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Routes>
                {loggedIn ? (
                    <>
                        <Route path="/" element={<Search/>}/>
                        <Route path="profile" element={<Profile/>}/>
                        <Route path="search" element={<Search/>}/>
                        <Route path="bookmarks" element={<Bookmarks/>}/>
                        <Route path="plan" element={<Plan me={me} loggedIn={loggedIn}
                                                          checkIfUserStillLoggedIn={checkIfUserStillLoggedIn}/>}/>
                        <Route path="invoices" element={<Invoices me={me}/>}/>
                        <Route path="impressum" element={<Impressum/>}/>

                        {me.is_admin &&
                        <Route path="userlist" element={<UserList/>}/>
                        }
                        {me.is_admin &&
                        <Route path="searchHistory" element={<SearchHistory/>}/>
                        }
                        {me.is_admin &&
                        <Route path="supportList" element={<SupportList/>}/>
                        }
                        <Route path="history" element={<History/>}/>
                        <Route path="support" element={<Support/>}/>
                        {me.is_admin && 
                        <Route path="newsbox" element={<NewsBox/>}/>
                        }
                         {me.is_admin && 
                        <Route path="statistics" element={<Statistics/>}/>
                        }
                        {me.is_admin &&
                        <Route path="coupon" element={<Coupon/>}/>
                        }
                        <Route path="*" element={<Search/>}/>
                    </>
                ) : (
                    <>
                        <Route path="impressum" element={<Impressum/>}/>
                        <Route path="betaaccessrequest" element={<BetaAccessRequest/>}/>
                        <Route path="/password/reset/confirm/*" element={<PasswordReset/>}/>
                        <Route path="/password/reset/request/*" element={<PasswordResetRequest/>}/>
                        <Route path="healthcheck" element={<Healthcheck/>}/>
                        <Route path="*" element={<SignIn/>}/>
                    </>
                )
                }
            </Routes>
        </div>
    )
}

export default Application
