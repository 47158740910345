import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const CustifySwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#02615D",
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#02615D",
    },
}));

export default CustifySwitch;
