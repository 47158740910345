import * as React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';

const Impressum = () => {

    return (
        <Container component="main" maxWidth="xl"

        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}
                      sx={{
                          // zIndex: "-1",
                      }}
                >
                    <Typography sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5"
                                component="div"
                                textAlign={"center"}>
                        <DataThresholdingIcon
                            sx={{
                                marginBottom: "-5px",
                                fontSize: "30px",
                            }}
                        />
                        &nbsp;Impressum
                    </Typography>

                    <Typography>CCC Cologne Customs &amp; Consulting GmbH</Typography>
                    <Typography>&nbsp;</Typography>
                    <Typography>Poll-Vingster Straße 107a</Typography>
                    <Typography>51105 Köln</Typography>
                    <Typography>Deutschland</Typography>

                    <Typography>Telefon +49 221 98351-0</Typography>
                    <Typography>Telefax +49 221 832990</Typography>
                    <Typography>E-Mail info@cologne-customs-consulting.de</Typography>
                    <Typography>www.cologne-customs-consulting.de</Typography>

                    <Typography>Geschäftsführer: Ralf Wieland, Vanessa Pedalina, Thorsten Heuser</Typography>
                    <Typography>Sitz der Gesellschaft: Köln</Typography>
                    <Typography>Eingetragen: Amtsgericht Köln HRB 100902</Typography>
                    <Typography>Umsatzsteuer-Identnummer: DE208331994</Typography>
                    <Typography>Steuernummer: 216/5865/1211</Typography>
                    <Typography>Erfüllungsort und Gerichtsstand für alle Ansprüche ist ausschließlich Köln.</Typography>

                    <Typography>Dieses Impressum gilt sowohl für die Domains www.cologne-customs-consulting.de als auch
                        für die
                        externen CCC-Präsenzen auf Facebook (Allgemein, Ausbildung), Google+, LinkedIn, Twitter und
                        XING.
                    </Typography>
                    <Typography>Datenschutz / Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir
                        keine Haftung
                        für
                        die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren
                        Betreiber
                        verantwortlich.</Typography>

                    <Typography>Bildnachweis der verwendeten Bilder:</Typography>
                    <Typography>©lily - stock.adobe.com, ©iStock.com/honglouwawa, ©iStock.com/stockfour</Typography>

                </Grid>
            </Grid>
        </Container>
    );
}

export default Impressum;