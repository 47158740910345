import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Container, TextField, Checkbox} from "@mui/material";
import { backgroundMain } from "../components/Colors";
import { styled } from "@mui/material/styles";

import AuthService from "../services/auth.service";
import NewsBoxService from "../services/newsbox.service";
import ErrorHandler from "../services/error.handler";

import BasicButton from "../components/buttons/BasicButton";

import SuperLoader from "../components/SuperLoader";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import Stack from "@mui/material/Stack";

export default function NewsBox() {

  const { t } = useTranslation();

  const [newsBoxList, setNewsBoxList] = useState([]);
  const [newsBoxText, setNewsBoxText] = useState("");
  const [newsBoxHeader, setNewsBoxHeader] = useState("");
  const [needAnswer, setNeedAnswer] = useState(false);

  const [newsBoxTextError, setNewsBoxTextError] = useState(false);
  const [newsBoxHeaderError, setNewsBoxHeaderError] = useState(false);
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [newsBoxId, setNewsBoxId] = useState("");

  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const ButtonContainer = styled("div")`
    text-align: center;
  `;
  const navigate = useNavigate();

  useEffect(() => {
    loadNewsBoxList();
  }, []);

  const loadNewsBoxList = async () => {
    setLoading(true);
    AuthService.isAccessTokenStillAlive()
      .then(function (response) {
        NewsBoxService.getNewsBoxList()
          .then(function (response) {
            setNewsBoxList(response?.data.news);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error.response.status + error.response.data.code);
            setMessage("Error: " + error.message);
            setLoading(false);
          });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          if (
            error.response.status === 401 &&
            error.response.data.code === "token_not_valid"
          ) {
            AuthService.refreshAccessToken()
              .then(() => {
                loadBookmarkList();
              })
              .catch((error) => {
                ErrorHandler.handleError(error);
                console.log("Refresh token has expired. Logout...");
                if (
                  error.response.status === 401 &&
                  error.response.data.code === "token_not_valid"
                ) {
                  AuthService.logout();
                  window.location.reload();
                }
              });
          } else if (error.response.status === 403) {
            navigate("/");
            window.location.reload();
          }
        } else if (error.request) {
          setMessage("Error: " + error.message);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  const addNewsBoxText = () => {
    setNewsBoxTextError(false);
    setNewsBoxHeaderError(false);

    if (!newsBoxText) {
      setNewsBoxTextError(true);
      setMessage(
        t("mandatoryFields", "Bitte füllen Sie alle geforderten Felder aus.")
      );
      return;
    }
    if (!newsBoxHeader) {
      setNewsBoxHeaderError(true);
      setMessage(
        t("mandatoryFields", "Bitte füllen Sie alle geforderten Felder aus.")
      );
      return;
    }

    let data = {
      text: newsBoxText,
      news_header: newsBoxHeader,
      need_answer: needAnswer,
    };
    NewsBoxService.createNewsBox(data)
      .then((response) => {
        console.log(response.status);
        if (response.status === 202) {
          console.log(response.data.message);
        }
        setNewsBoxText("");
        setNewsBoxHeader("");
        setNeedAnswer(false);
        loadNewsBoxList();
      })
      .catch((error) => {
        // expired access token
        console.log(error);
        console.log(error.response.data.code);
        console.log("Access token has expired. Refreshing...");
        if (
          error.response.status === 401 &&
          error.response.data.code === "token_not_valid"
        ) {
          AuthService.refreshAccessToken()
            .then(() => {
              loadNewsBoxList();
            })
            .catch((error) => {
              // expired refresh token => logout
              console.log(error.response.status);
              console.log(error.response.data.code);
              console.log("Refresh token has expired. Logout...");
              if (
                error.response.status === 401 &&
                error.response.data.code === "token_not_valid"
              ) {
                AuthService.logout();
                // navigate('/');
                window.location.reload();
              }
            });
        } else if (error.response.status === 403) {
          navigate("/");
          window.location.reload();
        }
      });
  };

  const updateNewsBoxText = () => {
    let data = {
      text: newsBoxText,
      news_header: newsBoxHeader,
      need_answer: needAnswer,
    };
    NewsBoxService.updateNewsBox(newsBoxId, data)
      .then((response) => {
        console.log(response.status);
        if (response.status === 202) {
          console.log(response.data.message);
        }
        setNewsBoxText("");
        setNewsBoxHeader("");
        setNewsBoxId("");
        setNeedAnswer(false);
        loadNewsBoxList();
      })
      .catch((error) => {
        // expired access token
        console.log(error);
        console.log(error.response.data.code);
        console.log("Access token has expired. Refreshing...");
        if (
          error.response.status === 401 &&
          error.response.data.code === "token_not_valid"
        ) {
          AuthService.refreshAccessToken()
            .then(() => {
              loadNewsBoxList();
            })
            .catch((error) => {
              // expired refresh token => logout
              console.log(error.response.status);
              console.log(error.response.data.code);
              console.log("Refresh token has expired. Logout...");
              if (
                error.response.status === 401 &&
                error.response.data.code === "token_not_valid"
              ) {
                AuthService.logout();
                // navigate('/');
                window.location.reload();
              }
            });
        } else if (error.response.status === 403) {
          navigate("/");
          window.location.reload();
        }
      });
  };

  const deleteNewsBoxText = (id) => {
    NewsBoxService.deleteNewsBox(id)
      .then((response) => {
        console.log(response.status);
        if (response.status === 202) {
          console.log(response.data.message);
        }
        loadNewsBoxList();
      })
      .catch((error) => {
        // expired access token
        console.log(error);
        console.log(error.response.data.code);
        console.log("Access token has expired. Refreshing...");
        if (
          error.response.status === 401 &&
          error.response.data.code === "token_not_valid"
        ) {
          AuthService.refreshAccessToken()
            .then(() => {
              loadNewsBoxList();
            })
            .catch((error) => {
              // expired refresh token => logout
              console.log(error.response.status);
              console.log(error.response.data.code);
              console.log("Refresh token has expired. Logout...");
              if (
                error.response.status === 401 &&
                error.response.data.code === "token_not_valid"
              ) {
                AuthService.logout();
                // navigate('/');
                window.location.reload();
              }
            });
        } else if (error.response.status === 403) {
          navigate("/");
          window.location.reload();
        }
      });
  };

  const onEditNextBoxText = (element) => {
    setNewsBoxId(element.id);
    setNewsBoxText(element.text);
    setNewsBoxHeader(element.news_header);
    setNeedAnswer(element.need_answer);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage(undefined);
  };

  const needAnswerChange = () => {
    setNeedAnswer(!needAnswer);
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box sx={{ width: 600, margin: "20px auto" }}>
        {message && (
          <Stack sx={{ width: "100%", marginTop: 3 }} spacing={2}>
            <Alert onClose={handleClose} severity={"error"}>
              {message}
            </Alert>
          </Stack>
        )}
         <TextField
          error={newsBoxHeaderError}
          margin="normal"
          name="header"
          label={t("News Header")}
          id="header"
          autoFocus
          value={newsBoxHeader}
          style={{ width: 600 }}
          onChange={(e) => setNewsBoxHeader(e.target.value)}
        />
        <TextField
          error={newsBoxTextError}
          margin="normal"
          name="text"
          label={t("News Text")}
          id="text"
          autoFocus
          value={newsBoxText}
          style={{ width: 600 }}
          onChange={(e) => setNewsBoxText(e.target.value)}
        />
        <FormControlLabel
            control={<Checkbox
                onChange={needAnswerChange}
                checked={needAnswer}
            />}
            label={t("Brauchen Sie eine Antwort?")} />
       
        <ButtonContainer>
          {newsBoxId ? (
            <BasicButton
              type="submit"
              variant="contained"
              text={t("EDIT")}
              noMargin={true}
              style={{ padding: 15, width: 200 }}
              onClick={updateNewsBoxText}
            />
          ) : (
            <BasicButton
              type="submit"
              variant="contained"
              text={t("ADD")}
              noMargin={true}
              style={{ padding: 15, width: 200 }}
              onClick={addNewsBoxText}
            />
          )}
        </ButtonContainer>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {loading && <SuperLoader />}
          <Demo>
            <List
              style={{ maxHeight: "200", overflow: "auto" }}
              sx={{
                backgroundColor: backgroundMain,
                maxHeight: 500,
                overflow: "auto",
              }}
            >
              {newsBoxList.length > 0
                ? newsBoxList.map((element, index) => {
                    return (
                      <ListItem
                        sx={{
                          marginTop: 2,
                          backgroundColor: "#f6f6f6",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <ListItemText primary={element.text} />
                        <CreateIcon  style={{ fill: '#0072ea' }} onClick={() => onEditNextBoxText(element)} />
                        <DeleteIcon
                          style={{ fill: '#FF0000' }}
                          onClick={() => deleteNewsBoxText(element.id)}
                        />
                      </ListItem>
                    );
                  })
                : ""}
            </List>
          </Demo>
        </Grid>
      </Grid>
    </Container>
  );
}
