import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Container} from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from '@mui/material/Typography';

import AuthService from "../services/auth.service";
import UserStatisticsService from "../services/statistics.service";
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function Statistics() {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const downloadFile = async () => {
    setLoading(true);
    AuthService.isAccessTokenStillAlive()
      .then(function (response) {
        UserStatisticsService.getUserStatistics()
          .then(async function (response) {
      
          
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'filename.xlsx';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
          })
          .catch((error) => {
            console.log(error.response.status + error.response.data.code);
            setMessage("Error: " + error.message);
            setLoading(false);
          });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          if (
            error.response.status === 401 &&
            error.response.data.code === "token_not_valid"
          ) {
            AuthService.refreshAccessToken()
              .then(() => {
                // loadBookmarkList();
              })
              .catch((error) => {
                ErrorHandler.handleError(error);
                console.log("Refresh token has expired. Logout...");
                if (
                  error.response.status === 401 &&
                  error.response.data.code === "token_not_valid"
                ) {
                  AuthService.logout();
                  window.location.reload();
                }
              });
          } else if (error.response.status === 403) {
            navigate("/");
            window.location.reload();
          }
        } else if (error.request) {
          setMessage("Error: " + error.message);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  const downloadFeedbackFile = async () => {
    setLoading(true);
    AuthService.isAccessTokenStillAlive()
      .then(function (response) {
        UserStatisticsService.getFeedbackResults()
          .then(async function (response) {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'filename.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error.response.status + error.response.data.code);
            setMessage("Error: " + error.message);
            setLoading(false);
          });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          if (
            error.response.status === 401 &&
            error.response.data.code === "token_not_valid"
          ) {
            AuthService.refreshAccessToken()
              .then(() => {
                // loadBookmarkList();
              })
              .catch((error) => {
                ErrorHandler.handleError(error);
                console.log("Refresh token has expired. Logout...");
                if (
                  error.response.status === 401 &&
                  error.response.data.code === "token_not_valid"
                ) {
                  AuthService.logout();
                  window.location.reload();
                }
              });
          } else if (error.response.status === 403) {
            navigate("/");
            window.location.reload();
          }
        } else if (error.request) {
          setMessage("Error: " + error.message);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box sx={{ width: 600, margin: "20px auto" }}>
        <Typography sx={{ mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D" }} variant="h5" component="div"
          textAlign={"center"}>
          {t('userStatistics', "Benutzerstatistiken")}:
        </Typography>
        <Box sx={{ width: 400, margin: "20px auto" }}>
          <Button
            sx={{ marginBottom: "20px", minWidth: 400 }}
            onClick={downloadFile}
            variant="contained"
            color="primary">
            {t('downloadUserStatistics', "Aktuelle Übersicht des Nutzerverhaltes")}
            <FileDownloadIcon />
          </Button>

          <Button
            sx={{ minWidth: 400 }}
            onClick={downloadFeedbackFile}
            variant="outlined"
            color="primary">
            {t('downloadFeedbackResults', "Download Texte aus Nachrichtenbox")}
            <FileDownloadIcon />
          </Button>
        </Box>

      </Box>
    </Container>
  );
}
