import * as React from 'react';
import {useState} from "react";
import {useTranslation} from "react-i18next";

import {useNavigate} from "react-router-dom";
import SearchService from "../services/search.service";
import AuthService from "../services/auth.service";

import SuperLoader from "../components/SuperLoader";
import SearchResults from "../components/SearchResults";
import FeedbackPopUp from "../components/FeedbackPopUp";

import CssBaseline from '@mui/material/CssBaseline';
import InputBase from '@mui/material/InputBase';
import Alert from "@mui/material/Alert";
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from "@mui/material/Stack";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import BookmarkService from "../services/bookmark.service";
import ErrorHandler from "../services/error.handler";

export default function Search() {


    const {t} = useTranslation();
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [search, setSearch] = useState("");
    const [searchResults, setSearchResults] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedTaxNumber, setSelectedTaxNumber] = useState();
    const [tipsOn, setTipsOn] = useState(true);

    const email = "custify@cologne-customs-consulting.de";
    const mailSubject = "Question from Custify search page.";

    const [tipHeader, setTipHeader] = useState(
        [t('tipHeader1'), t('tipHeader2'), t('tipHeader3'), t('tipHeader4'), t('tipHeader5')]
    );

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    const handleChange = () => {
        if(!search) {
            setMessage("Geben Sie bitte mindestens ein Stichwort ein, um eine Suchanfrage zu starten.");
            return;
        }
        console.log("Searching");
        setMessage(null);
        setSearchResults(null);
        setTipsOn(false);
        setLoading(true);
        SearchService.createSearch(search)
            .then((response) => {
                setSearchResults(response.data.suggested_items);
                console.log(response.data);
                setLoading(false);
            })
            .catch((error) => {
                // expired access token
                // console.log(error.response.status);
                // console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            console.log("Refreshed.");
                            setLoading(false);
                            handleChange();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                setLoading(false);
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                            setLoading(false);
                        });
                }
                else if (error.response.status === 402) {
                    console.log("Too many requests...");
                    setMessage(t('Ihre Testphase ist leider abgelaufen. Bitte ändern Sie Ihre Lizenz.'));
                } 
                else if (error.response.status === 429) {
                    console.log("Too many requests...");
                    setMessage(t('waitTenSeconds'));
                } else if (error.response.status === 403) {
                    setLoading(false);
                    navigate('/');
                    window.location.reload();
                } else if (error.response.status === 500) {
                    console.log(error.response.data);
                    setMessage(error.response.data.error);
                }
                setLoading(false);
            })
    };

    const clearSearch = () => {
        setSearch("");
    }

    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
    }

    const keyPressed = (e) => {
        // console.log('value', e.keyCode);
        if (e.keyCode === 13) {
            handleChange();
        }
    }

    const tipRow = (tip, index) => {
        let tipWithEmail = false;
        let array = [];

        if (tipHeader[index].includes("MAIL")) {
            tipWithEmail = true;
            array = tipHeader[index].split("MAIL");
        }

        return (
            <>
                {!tipWithEmail &&
                <Typography sx={{paddingTop: '10px', paddingLeft: '70px'}} variant="h5" color="text.secondary">
                    &#8226; {tipHeader[index]}
                </Typography>
                }
                {tipWithEmail &&
                <Typography sx={{paddingTop: '10px', paddingLeft: '70px'}} variant="h5" color="text.secondary">
                    &#8226; {array[0]}
                    <Link
                        onClick={(e) => {
                            e.preventDefault();
                            window.location = "mailto:" + email + "?subject=" + mailSubject;
                        }}
                    >
                        {email}
                    </Link>
                    {array[1]}
                </Typography>
                }
            </>
        )
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#9FD0C7",
                    display: 'flex',
                    paddingTop: 5,
                    paddingBottom: 5,
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant={'h6'}
                    sx={{
                        p: '0 55px 2px 0',
                        display: 'inline-flex',
                        fontWeight: 'bold',
                        alignItems: 'center',
                        boxShadow: "none",
                        color: "#2A292A"
                    }}
                >{t('searchHeader')}</Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <Paper
                        sx={{
                            p: '2px 4px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            width: {xs: 300, sm: 400, md: 500},
                            borderRadius: 3,
                            // border: 2,
                            borderColor: "#c20a79",
                            boxShadow: "none"
                        }}
                    >
                        <InputBase
                            id="searchInput"
                            onChange={handleChangeSearch}
                            onKeyDown={keyPressed}
                            sx={{ml: 1, mb: -.6, flex: 1}}
                            placeholder={t('searchHint')}
                            value={search}
                            inputProps={{'aria-label': 'Geben Sie Stichwörter ein'}}
                        />

                        <IconButton
                            onClick={clearSearch}>
                            <CloseIcon/>
                        </IconButton>
                    </Paper>
                    <IconButton
                        onClick={handleChange}
                        sx={{p: '15px'}}
                        aria-label="search">
                        <SearchIcon/>
                    </IconButton>
                </Box>
            </Box>
            <Container
                maxWidth="xl"
                component="main">
                <CssBaseline/>
                {loading &&
                    <>

                        <Typography
                            sx={{
                                mt: 3.4, mb: 3,
                                fontWeight: "bold",
                                color: "#02615D"
                            }}
                            variant="h6" component="div"
                            textAlign={"center"}>
                            {t('ratePlanWhenRegisterHeader11', "EZT- und vZTA-Datenbanken werden gescannt...")} <SuperLoader/>
                        </Typography>
                    </>
                }
                {message && (
                    <Stack sx={{width: '100%', marginTop: 4.4}} spacing={2}>
                        <Alert
                            onClose={handleClose}
                            severity={severity}>{message}</Alert>
                    </Stack>
                )}
                {!loading && tipsOn &&
                <Box sx={{flexGrow: 1, paddingTop: 4, display: 'inline-block'}}>
                    <TipsAndUpdatesOutlinedIcon sx={{
                        fontSize: "50px",
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        color: '#8B8689'
                    }}>
                    </TipsAndUpdatesOutlinedIcon>
                    <Typography variant="h4" color="text.secondary"
                                sx={{display: 'inline-block', verticalAlign: 'middle', color: '#8B8689'}}>
                        &nbsp; {t('searchTips','Tipps für die Suche')}
                    </Typography>
                    {tipHeader.map((tipRow))}
                </Box>
                }
                {searchResults && !tipsOn &&
                <SearchResults
                    searchResults={searchResults}
                    setSearchResults={setSearchResults}
                    selectedTaxNumber={selectedTaxNumber}
                    setSelectedTaxNumber={setSelectedTaxNumber}
                />
                }
            </Container>

        </>
    );
}