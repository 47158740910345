import React from "react";
import {useTranslation} from 'react-i18next'
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Moment from 'moment';

import AuthService from "../services/auth.service";
import SupportService from "../services/support.service";
import ErrorHandler from "../services/error.handler";

import SearchResults from "../components/SearchResults";
import SuperLoader from "../components/SuperLoader";
import CustifySwitch from "../components/CustifySwitch";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import Switch from "@mui/material/Switch";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Button from "@mui/material/Button";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Support = (props) => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [dense, setDense] = useState(true);
    const [showClosedTickets, setShowClosedTickets] = useState(true);
    const steps = ['Back to history', 'Search'];

    const [ticketList, setTicketList] = useState([]);
    const [currentSearch, setCurrentSearch] = useState("");
    const [searchOpened, setSearchOpened] = useState();
    const [searchResults, setSearchResults] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const [selectedTaxNumber, setSelectedTaxNumber] = useState();


    const Demo = styled('div')(({theme}) => ({
        backgroundColor: theme.palette.background.paper,
        // backgroundColor: "red",
    }));

    const navigate = useNavigate();

    useEffect(() => {
        loadSupportList();
    }, []);

    const loadSupportList = async () => {
        setLoading(true);
        AuthService.getCurrentUserData()
            .then(function (response) {
                // handle success
                // console.log(response.data.id);
                SupportService.listTicketsForUser(response.data.id, props.url)
                    .then(function (response) {
                        console.log(response.data);
                        setTicketList(response?.data);
                        setLoading(false);
                    })
                    .catch((error) => {
                        // expired access token
                        ErrorHandler.handleError(error);
                        console.log("Access token has expired. Refreshing...");
                        if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                            AuthService.refreshAccessToken()
                                .then(() => {
                                    // loadSupportList();
                                })
                                .catch((error) => {
                                    // expired refresh token => logout
                                    ErrorHandler.handleError(error);
                                    console.log("Refresh token has expired. Logout...");
                                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                        AuthService.logout();
                                        // navigate('/');
                                        window.location.reload();
                                    }
                                });
                        } else if (error.response.status === 403) {
                            navigate('/');
                            window.location.reload();
                        }
                    })
            })
            .catch((error) => {
                ErrorHandler.handleError(error);
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            // loadUserList();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            ErrorHandler.handleError(error);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
    };

    function ticket(element, index) {
        if (!showClosedTickets && !element.is_open) {
            return null;
        }

        return (
            <ListItem
                sx={{
                    "&:hover": {
                        backgroundColor: "#f6f6f6",
                        cursor: "pointer"
                    }
                }}
                onClick={() => openTicket(element.url)}
                secondaryAction={
                    <Typography variant={!element.is_open ? "h6" : "h5"} color={!element.is_open ? "text.secondary" : "text.primary"}>
                        {element.is_open ? "open" : "closed"}
                    </Typography>
                }
            >
                <ListItemAvatar>
                    <Avatar
                        sx={{ bgcolor: element.is_open === true ? "#de1293" : "lightgrey" }}>
                        <SupportAgentIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={element.title}
                    secondary={Moment(element.created_on).format('D.MM.YYYY')}
                />
            </ListItem>
        );
    }

    const openTicket = (url) => {
        // console.log("Searching" + url);
        setLoading(true);
        SupportService.ticketDetail(url)
            .then((response) => {
                console.log(response.data);
                setSearchResults(response.data.suggested_items);
                setSelectedTaxNumber(response.data.selected_tax_number)
                setLoading(false);
                if (props.sendToParent) {
                    props.sendToParent(2);
                }
            })
            .catch((error) => {
                // expired access token
                console.log(error);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            setLoading(false);
                            openTicket(url);
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                setLoading(false);
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                            setLoading(false);
                        });
                } else if (error.response.status === 403) {
                    setLoading(false);
                    navigate('/');
                    window.location.reload();
                }
                setLoading(false);
            })

        setSearchOpened(true);
        setActiveStep(1);
        if (props.setClickSecondStep) {
            props.setClickSecondStep(false);
        }
        setCurrentSearch(url);
    }

    const handleStep = (index) => {
        if (index === 0) {
            setSearchOpened(false);
            setCurrentSearch("");
            setActiveStep(0);
        }
    }

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
    };

    const showHideClosedTickets = () => {
        setShowClosedTickets((showClosedTickets) => !showClosedTickets)
    }

    return (
        <Container component="main" maxWidth="xl">
            <Grid item xs={12} md={12}>

                {message && (
                    <Stack sx={{width: '100%', marginTop: 4.4}} spacing={2}>
                        <Alert
                            onClose={handleCloseMessage}
                            severity={severity}>{message}</Alert>
                    </Stack>
                )}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    {!props.parent &&
                    <Typography sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5" component="div"
                                textAlign={"center"}>
                        Support ticket list:
                    </Typography>
                    }
                    <Button
                        sx={{
                            marginLeft: "20px",
                            backgroundColor: "#de1293",
                            color: "white",
                        }}>
                        <AddCircleOutlineIcon/><span>&nbsp;Create new support ticket</span>
                    </Button>
                    {!props.parent && !loading && searchOpened &&
                    <Typography sx={{marginLeft: "30px", cursor: "pointer"}} onClick={() => handleStep(0)}><ListAltIcon/><KeyboardBackspaceIcon/></Typography>
                    }
                    {loading && <SuperLoader/>}
                    {(!searchOpened || props.clickSecondStep) &&
                    <Demo>
                        <List dense={dense}>
                            <ListItem>
                                <ListItemText primary="Show closed tickets"/>
                                <CustifySwitch
                                    edge="end"
                                    onChange={showHideClosedTickets}
                                    checked={showClosedTickets}
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-wifi',
                                        'aria-label': t('showSearchesWithNoTax')
                                    }}
                                />
                            </ListItem>
                            {ticketList.map(ticket)}
                        </List>
                    </Demo>
                    }
                    {searchOpened && !props.clickSecondStep &&
                    <SearchResults
                        searchResults={searchResults}
                        selectedTaxNumber={selectedTaxNumber}
                        setSelectedTaxNumber={setSelectedTaxNumber}
                    />
                    }
                </Grid>
            </Grid>
        </Container>

    );
};

export default Support;
