import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_BOOKMARK_ADD = API_URL + "/bookmarks/";
const API_URL_BOOKMARK_LIST = API_URL + "/bookmarks/";
const API_URL_BOOKMARK_DELETE = API_URL + "/bookmarks/";


// Add a request interceptor
const axios_search = axios.create();
axios_search.interceptors.request.use(function (config) {
    let token = AuthService.getCurrentUser();
    config.headers.Authorization = 'JWT ' + token.access;
    return config;
});

const bookmarkList = () => {
    let token = AuthService.getCurrentUser();
    return axios_search.get(API_URL_BOOKMARK_LIST, {
        headers: {
            'Authorization': 'JWT ' + token.access,
        }
    });
};

const bookmarkCreate = (taxNumber, name) => {
    let token = AuthService.getCurrentUser();
    return axios_search.put(API_URL_BOOKMARK_ADD + taxNumber + "/",
        {
            "name": name
        }
    );
};

const bookmarkDelete = (taxNumber) => {
    let token = AuthService.getCurrentUser();
    return axios.delete(API_URL_BOOKMARK_DELETE + taxNumber + "/",
        {
            headers: {
                'Authorization': 'JWT ' + token.access,
            }
        });
};


export default {
    bookmarkList,
    bookmarkCreate,
    bookmarkDelete
};
