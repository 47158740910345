import * as React from 'react';
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next";
import Moment from 'moment';
import {useEffect, useState} from "react";
import CssBaseline from '@mui/material/CssBaseline';
import axios from "axios";

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {styled} from '@mui/material/styles';
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import RateService from "../services/rate.service";
import AuthService from "../services/auth.service";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';

const Invoice = (props) => {

    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [loading, setLoading] = useState(false);

    const {
        invoiceNumber,
        me
    } = props;

    const Demo = styled('div')(({theme}) => ({
        backgroundColor: theme.palette.background.paper,
        // backgroundColor: "red",
    }));

    const {t} = useTranslation();
    const navigate = useNavigate();
    const [invoiceData, setInvoiceData] = useState({
        created_on: "",
        download_url: "",
        pdf_is_uploaded: false,
        rateuser: {
            rate: {
                name: "",
                price_euro: 0
            },
            user: {
                name: ""
            }
        }
    });

    useEffect(() => {
        loadInvoice();
    }, []);

    const loadInvoice = () => {
        RateService.getInvoice(invoiceNumber)
            .then((response) => {
                console.log(response.data);
                setInvoiceData(response.data);
            })
            .catch((error) => {
                // expired access token
                console.log(error);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            loadInvoice();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            // console.log(error.response.status);
                            // console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
        // loadInvoice();
    }

    const UploadFileToS3WithReactS3 = () => {

        const [selectedFile, setSelectedFile] = useState(null);
        const handleFileInput = (e) => {
            setSelectedFile(e.target.files[0]);
        }

        const handleUpload = async (file) => {
            if(!file) return;
            setLoading(true);
            await axios.put(invoiceData.upload_url, file, {
                headers: {
                    'Content-Type': file.type
                }
            })
                .then(function (response) {
                    console.log(response);
                    if (response.status === 200 && response.statusText === "OK") {
                        let data = {
                            "pdf_is_uploaded": true
                        }

                        RateService.partitiallyUpdateInvoice(invoiceNumber, data)
                            .then(function (response) {
                                console.log(response.data);
                                setMessage(t("invoiceWasUploaded","Die Datei wurde erfolgreich hochgeladen"));
                                setSeverity("success");
                                setLoading(false);
                            })
                            .catch((error) => {
                                console.log(error.response.status);
                                setMessage(t("invoiceWasNotUploaded","Die Datei wurde nicht erfolgreich hochgeladen"));
                                setSeverity("error");
                                setLoading(false);
                            })

                    }
                })
                .finally(() => setLoading(false));
        }

        return <div>
            <br/>
            <div>{t("uploadPdf","Pdf hochladen:")}:</div>
            <input type="file" onChange={handleFileInput}/>
            <LoadingButton
                loading={loading}
                onClick={() => handleUpload(selectedFile)}
                size="small"
                loadingIndicator={<CircularProgress color='info' size={16} />}
                sx={{
                    padding: "6px 10px 3px 10px",
                    borderRadius: "0",
                    backgroundColor: '#02615D',
                    color: "white",
                    textTransform: "none",
                    '&:hover': {
                        backgroundColor: '#c5ffea',
                        // backgroundColor: "#02615D",
                    },
                }}
            >{t("uploadPdf","Pdf hochladen:")}</LoadingButton>
        </div>
    }

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
        setSeverity("error");
    };

    return (
        <Container
            maxWidth="xl"
            component="main">
            <Grid item xs={12} md={12}>

                {message && (
                    <Stack sx={{width: '100%', marginTop: 4.4}} spacing={2}>
                        <Alert
                            onClose={handleCloseMessage}
                            severity={severity}>{message}</Alert>
                    </Stack>
                )}
            </Grid>
            <CssBaseline/>

            <Paper
                sx={{
                    p: '20px',
                }}
            >
                <Box sx={{flexGrow: 1, paddingLeft: '8px'}}>
                    <Typography
                        variant={'h6'}

                    ><strong>Rechnung: </strong> {Moment(invoiceData.created_on).format('D.MM.YYYY')}</Typography>
                    <Typography
                        variant={'h6'}
                    ><strong>Gesamtkosten: </strong> {invoiceData.rateuser.rate.price_euro} &#8364;</Typography>
                    <Typography
                        variant={'h6'}
                    ><strong>Benutzer: </strong> {invoiceData.rateuser.user.name}</Typography>
                    <Typography
                        variant={'h6'}
                        sx={{
                            p: '0 0 12px 0',
                        }}
                    ><strong>Preis: </strong> {invoiceData.rateuser.rate.name}</Typography>

                    {invoiceData.pdf_is_uploaded &&
                    <a type="button" href={invoiceData.download_url} target="_blank" download>
                        <Button
                            size="small"
                            sx={{
                                padding: "6px 10px 3px 10px",
                                borderRadius: "0",
                                backgroundColor: '#9FD0C7',
                                color: "white",
                                textTransform: "none",
                                '&:hover': {
                                    // backgroundColor: '#c5ffea',
                                    backgroundColor: "#02615D",
                                },
                            }}
                        >Pdf herunterladen</Button>
                    </a>
                    }
                    {!invoiceData.pdf_is_uploaded &&
                    <Typography
                        variant={'h6'}
                        sx={{
                            backgroundColor: '#9FD0C7',
                            color: "white",
                            p: '0 0 0 5px'
                        }}
                    > {t('pdfIsNotUploadedYet', ' Die Download-Schaltfläche wird angezeigt, wenn die Rechnung vom Administrator hochgeladen wird!')}</Typography>
                    }

                    {me.is_admin &&

                    <UploadFileToS3WithReactS3/>
                    }
                </Box>
            </Paper>
        </Container>
    );
}

export default Invoice;
