import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_SEARCH = process.env.REACT_APP_API_URL + "/search/searches/";
const API_URL_SEARCH_USERS = process.env.REACT_APP_API_URL + "/search/users/";

// Add a request interceptor
const axios_search = axios.create();
axios_search.interceptors.request.use(function (config) {
    let token = AuthService.getCurrentUser();
    config.headers.Authorization = 'JWT ' + token.access;

    return config;
});

const createSearch = (data) => {
    let token = AuthService.getCurrentUser();
    return axios_search.post(API_URL_SEARCH,
        {
            "user_input": data
        },
    );
};

const searchDetail = (url) => {
    let token = AuthService.getCurrentUser();
    return axios_search.get(API_URL + url, {
        headers: {
            'Authorization': 'JWT ' + token.access,
        }
    });
};
const updateSearchSelectedTaxNumber = (url) => {
    return axios_search.put(API_URL + url);
};
const listUsersAndSearchMetadata = () => {
    return axios_search.get(API_URL_SEARCH_USERS);
};
const listSearchesForUser = (userId, url, page) => {
    if (url) {
        return axios_search.get(API_URL + url);
    }
    return axios_search.get(API_URL_SEARCH_USERS + userId + (page ? page : ""));
};

export default {
    createSearch,
    searchDetail,
    updateSearchSelectedTaxNumber,
    listUsersAndSearchMetadata,
    listSearchesForUser
};
