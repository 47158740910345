import * as React from 'react';

import AuthService from "../../services/auth.service";

import { useTranslation } from 'react-i18next'
import { useState } from "react";
import SuperLoader from "../../components/SuperLoader";
import BasicButton from "../../components/buttons/BasicButton";

import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Alert from "@mui/material/Alert";

import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import KeyIcon from '@mui/icons-material/Key';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

const theme = createTheme();

export default function PasswordResetRequest() {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [messageMarginTop, setMessageMarginTop] = useState(6);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);

        let email = data.get('email');

        AuthService.requirePasswordResetLinkBeforeLogin(email)
            .then(function (response) {
                if (response.request.status == 201 || response.request.status == 204) {
                    setMessage(t('resetPasswordLinkSent', 'Falls der Benutzer existiert, erhalten Sie eine E-Mail mit dem Link zum Zurücksetzen des Passworts'));
                    setSeverity("success");
                }

                setMessageMarginTop(2);
                setLoading(false);
            })
            .catch(function (error) {
                let error_key = "";
                let error_text = "";

                Object.keys(error.response.data)
                    .forEach(function eachKey(key) {
                        error_key = key; // alerts key
                        error_text = error.response.data[key]; // alerts value
                    });
                console.log(error_text);
                setMessage(error_text);
                setSeverity("error");
                setLoading(false);
                setMessageMarginTop(1);
            });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
        setMessageMarginTop(6);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                {message && (
                    <Stack sx={{width: '100%', marginTop: 5}} spacing={2}>
                        <Alert
                            onClose={handleClose}
                            severity={severity}>{message}</Alert>
                    </Stack>
                )}
                <Box
                    sx={{
                        marginTop: messageMarginTop,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {!message &&
                    <Typography sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5"
                                component="div"
                                textAlign={"center"}>
                        <KeyIcon
                            sx={{
                                marginBottom: "-5px",
                                fontSize: "30px",
                            }}
                        />
                        &nbsp;{t('requestNewPassword', 'geben sie ihre E-Mail Adresse ein')}
                    </Typography>

                    }
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="email"
                            label={t('email')}
                            type="email"
                            id="email"
                            autoFocus
                        />

                        {!loading &&
                        <BasicButton
                            size="large"
                            startIcon={<ForwardToInboxIcon/>}
                            type="submit"
                            fullWidth
                            variant="contained"
                            text={t('sendResetEmail', 'Link zum Zurücksetzen des Passworts senden')}
                        >

                        </BasicButton>
                        }
                        {loading &&
                        <SuperLoader/>
                        }
                    </Box>
                </Box>
                {/*<Copyright sx={{ mt: 8, mb: 4 }} />*/}
            </Container>
        </ThemeProvider>
    );
}