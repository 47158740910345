import axios from "axios";

const handleError = (error) => {
    console.log("Error handler:");
    console.log(error.response.status);
    console.log(error.response.data.code);
    if(error.response.data.code === "super_error") {
        logError(error);
    }
};

const logError = (error) => {
    // todo
    console.log("Error handler:");
    console.log(error.response.status);
    console.log(error.response.data.code);
};

export default {
    handleError,
};