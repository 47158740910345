import React from "react";
import {useTranslation} from 'react-i18next'
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Moment from 'moment';

import AuthService from "../services/auth.service";
import SearchService from "../services/search.service";
import ErrorHandler from "../services/error.handler";

import {backgroundMain} from "../components/Colors";

import SearchResults from "../components/SearchResults";
import SuperLoader from "../components/SuperLoader";

import Alert from "@mui/material/Alert";
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Pagination from '@mui/material/Pagination';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import Cancel from '@mui/icons-material/Cancel';

import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const History = (props) => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [dense, setDense] = useState(true);

    const [searchHistoryList, setSearchHistoryList] = useState([]);
    const [currentSearch, setCurrentSearch] = useState("");
    const [searchOpened, setSearchOpened] = useState();
    const [searchResults, setSearchResults] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const [selectedTaxNumber, setSelectedTaxNumber] = useState();
    const [numberOfPages, setNumberOfPages] = useState(1);


    const Demo = styled('div')(({theme}) => ({
        backgroundColor: theme.palette.background.paper,
        // backgroundColor: "red",
    }));

    const navigate = useNavigate();

    useEffect(() => {
        loadSearchHistory();
    }, []);

    const loadSearchHistory = async () => {
        setLoading(true);
        AuthService.getCurrentUserData()
            .then(function (response) {
                SearchService.listSearchesForUser(response.data.id, props.url)
                    .then(function (response) {
                        console.log(response.data.results);
                        setSearchHistoryList(response?.data.results);
                        setNumberOfPages(Math.floor(response.data.count / 10) + 1);
                        setLoading(false);
                    })
                    .catch((error) => {
                        // expired access token
                        ErrorHandler.handleError(error);
                        console.log("Access token has expired. Refreshing...");
                        if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                            AuthService.refreshAccessToken()
                                .then(() => {
                                    loadSearchHistory();
                                })
                                .catch((error) => {
                                    // expired refresh token => logout
                                    ErrorHandler.handleError(error);
                                    console.log("Refresh token has expired. Logout...");
                                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                        AuthService.logout();
                                        // navigate('/');
                                        window.location.reload();
                                    }
                                });
                        } else if (error.response.status === 403) {
                            navigate('/');
                            window.location.reload();
                        }
                    })
            })
            .catch((error) => {
                ErrorHandler.handleError(error);
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            loadSearchHistory();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            ErrorHandler.handleError(error);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
    };

    function searchElement(element, index) {
        return (
            <ListItem
                sx={{
                    "&:hover": {
                        backgroundColor: "#f6f6f6",
                        cursor: "pointer"
                    }
                }}
                onClick={() => openSearchComponent(element.url)}
                secondaryAction={
                    <Typography>
                        {Moment(element.created_on).format('D.MM.YYYY')}
                    </Typography>
                }
            >
                <ListItemAvatar>
                    {element.raised_exception
                        ? <Avatar><Cancel/></Avatar>
                        : <Avatar><ScreenSearchDesktopIcon/></Avatar>
                    }
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        textTransform: "uppercase"
                    }}
                    primary={element.user_input ? element.user_input : "..."}
                    // secondary={Moment(element.created_on).format('D.MM.YYYY')}
                />
            </ListItem>
        );
    }

    const openSearchComponent = (url) => {
        // console.log("Searching" + url);
        setLoading(true);
        SearchService.searchDetail(url)
            .then((response) => {
                console.log(response.data);
                setSearchResults(response.data.suggested_items);
                setSelectedTaxNumber(response.data.selected_tax_number)
                setLoading(false);
                if (props.sendToParent) {
                    props.sendToParent(2);
                }
            })
            .catch((error) => {
                // expired access token
                console.log(error);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            setLoading(false);
                            openSearchComponent(url);
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                setLoading(false);
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                            setLoading(false);
                        });
                } else if (error.response.status === 403) {
                    setLoading(false);
                    navigate('/');
                    window.location.reload();
                }
                setLoading(false);
            })

        setSearchOpened(true);
        setActiveStep(1);
        if (props.setClickSecondStep) {
            props.setClickSecondStep(false);
        }
        setCurrentSearch(url);
    }

    const handleStep = (index) => {
        if (index === 0) {
            setSearchOpened(false);
            setCurrentSearch("");
            setActiveStep(0);
        }
    }

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
    };

    const changePage = (event, value) => {
        console.log(props.url);
        AuthService.getCurrentUserData()
            .then(function (response) {
                SearchService.listSearchesForUser(response.data.id, null, "?page=" + value)
                    .then(function (response) {
                        console.log(response.data.results);
                        setSearchHistoryList(response?.data.results);
                        setNumberOfPages(Math.floor(response.data.count / 10) + 1);
                        setLoading(false);
                    })
                    .catch((error) => {
                        // expired access token
                        ErrorHandler.handleError(error);
                        console.log("Access token has expired. Refreshing...");
                        if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                            AuthService.refreshAccessToken()
                                .then(() => {
                                    loadSearchHistory();
                                })
                                .catch((error) => {
                                    // expired refresh token => logout
                                    ErrorHandler.handleError(error);
                                    console.log("Refresh token has expired. Logout...");
                                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                        AuthService.logout();
                                        // navigate('/');
                                        window.location.reload();
                                    }
                                });
                        } else if (error.response.status === 403) {
                            navigate('/');
                            window.location.reload();
                        }
                    })
            })
            .catch((error) => {
                ErrorHandler.handleError(error);
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            loadSearchHistory();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            ErrorHandler.handleError(error);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })

    }

    return (
        <Container component="main" maxWidth="xl"

        >
            <Grid item xs={12} md={12}>

                {message && (
                    <Stack sx={{width: '100%', marginTop: 4.4}} spacing={2}>
                        <Alert
                            onClose={handleCloseMessage}
                            severity={severity}>{message}</Alert>
                    </Stack>
                )}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}
                      sx={{
                          // zIndex: "-1",
                      }}
                >
                    {!props.parent &&
                    <Typography sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5"
                                component="div"
                                textAlign={"center"}>
                        <ManageSearchIcon
                            sx={{
                                marginBottom: "-5px",
                                fontSize: "30px",
                            }}
                        />
                        {t('searchHistory')}
                    </Typography>
                    }
                    {!props.parent && !loading && searchOpened &&
                    <Typography sx={{marginLeft: "30px", cursor: "pointer"}}
                                onClick={() => handleStep(0)}>
                        <ListAltIcon/>
                        <KeyboardBackspaceIcon/>
                    </Typography>
                    }
                    {loading && <SuperLoader/>}
                    {(!searchOpened || props.clickSecondStep) &&
                    <Demo>
                        <List dense={dense}
                        sx={{
                            backgroundColor: backgroundMain,
                        }}
                        >
                            {searchHistoryList.map(searchElement)}
                        </List>
                    </Demo>
                    }
                    {searchOpened && !props.clickSecondStep &&
                    <SearchResults
                        searchResults={searchResults}
                        setSearchResults={setSearchResults}
                        selectedTaxNumber={selectedTaxNumber}
                        setSelectedTaxNumber={setSelectedTaxNumber}
                    />
                    }
                    {!searchOpened &&
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={5}>
                            <Pagination
                                onChange={changePage}
                                sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5" component="div"
                                count={numberOfPages}/>
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Grid>

        </Container>

    );
};

export default History;
