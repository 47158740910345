import axios from "axios";
import AuthService from "./auth.service";

const API_URL = process.env.REACT_APP_API_URL;
const RATE = API_URL + "/payment/rates/";
const RATE_4_USER = API_URL + "/payment/ratesuser/";

const INVOICE_LIST = API_URL + "/payment/invoice-list/";
const INVOICE = API_URL + "/payment/invoice/";

const REATURE_LIST = API_URL + "/payment/features/";
const INVOICE_ = API_URL + "/payment/invoice/";

// Add a request interceptor
const axios_tiers = axios.create();
axios_tiers.interceptors.request.use(function (config) {
    let token = AuthService.getCurrentUser();
    config.headers.Authorization = 'JWT ' + token.access;
    return config;
});

const listTiers = () => {
    // return axios_tiers.get(RATE);
    return axios.get(RATE);
};

const createTier = (data) => {
    let token = AuthService.getCurrentUser();
    return axios_tiers.post(RATE,
        data,
    );
};

const updateTier = (data) => {
    console.log(data);
    let token = AuthService.getCurrentUser();
    return axios.put(RATE + data.id + "/",
        data,
        {
            headers: {
                'Authorization': 'JWT ' + token.access,
            }
        });
};

const deleteTier = (id) => {
    let token = AuthService.getCurrentUser();
    return axios_tiers.delete(RATE + id + "/",
        {
            headers: {
                'Authorization': 'JWT ' + token.access,
            }
        });
};

const getUserRate = (user_id) => {
    return axios_tiers.get(RATE_4_USER + user_id + "/");
};

const createUserRate = (data) => {
    return axios_tiers.post(RATE_4_USER,
        data,
    );
};

const updateUserRate = (id, data) => {
    return axios.put(RATE_4_USER + id + "/",data);
};

const deleteUserRate = (user_id) => {
    let token = AuthService.getCurrentUser();
    return axios_tiers.delete(RATE_4_USER + user_id + "/",
        {
            headers: {
                'Authorization': 'JWT ' + token.access,
            }
        });
};

const invoiceList = () => {
    return axios_tiers.get(INVOICE_LIST);
};

const getInvoice = (id) => {
    return axios_tiers.get(INVOICE + id + "/");
}

const partitiallyUpdateInvoice = (invoice_id, data) => {
    let token = AuthService.getCurrentUser();
    return axios.patch(INVOICE + invoice_id + "/", data,
        {
            headers: {
                'Authorization': 'JWT ' + token.access,
            }
        });

}

const getFeatureList = () => {
        return axios.get(REATURE_LIST);
}

export default {
    listTiers,
    createTier,
    updateTier,
    deleteTier,
    getUserRate,
    createUserRate,
    updateUserRate,
    deleteUserRate,
    invoiceList,
    getInvoice,
    partitiallyUpdateInvoice,
    getFeatureList
};
