import * as React from 'react';
import {useTranslation} from "react-i18next";
import Moment from 'moment';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import SearchService from "../services/search.service";
import BookmarkService from "../services/bookmark.service";
import AuthService from "../services/auth.service";

import {backgroundMain} from './Colors';

import RichObjectTreeView from "./NestedTreeView";
import TileRounded from "./TileRounden";
import BasicButton from "./buttons/BasicButton";

import CssBaseline from '@mui/material/CssBaseline';
import TableCell from '@mui/material/TableCell';
import ListItemText from "@mui/material/ListItemText";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReorderIcon from '@mui/icons-material/Reorder';
import GridViewIcon from '@mui/icons-material/GridView';
import ImageList from '@mui/material/ImageList';
import GppGoodIcon from '@mui/icons-material/GppGood';
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DoneIcon from '@mui/icons-material/Done';
import { MenuItem, FormControl, Select, InputLabel  } from '@mui/material';
import { countries } from '../constants/countries';

const SearchResults = (props) => {

    const style = {
        margin: "50px auto",
        maxWidth: "95%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        border: "0"
    };

    const {
        searchResults
    } = props;

    const Demo = styled('div')(({theme}) => ({
        backgroundColor: theme.palette.background.paper,
        // backgroundColor: "red",
    }));

    const {t} = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [docModalData, setDocModalData] = useState([]);
    const [taxNumber, setTaxNumber] = useState("");
    const [treeData, setTreeData] = useState();
    const [showList, setShowList] = useState(true);
    const [saveBookmark, setSaveBookmark] = useState(false);
    const [bookmarkName, setBookmarkName] = useState("");
    const [destinationCountry, setDestinationCountry] = useState(''); 

    const navigate = useNavigate();

    const handleClose = () => {
        setIsModalOpen(false)
        setTreeData(null);
        setDocModalData([]);
    }

    const handleExportModalClose = () => {
        setIsExportModalOpen(false);
        setTaxNumber("");
        setDestinationCountry("");
    }

    const selectAsBestMatch = (ind, url, tax_number) => {
        props.setSelectedTaxNumber(tax_number);
        console.log(url);
        SearchService.updateSearchSelectedTaxNumber(url)
            .then((response) => {
                console.log(response.status);
                if (response.status === 202) {
                    console.log(response.data.message);
                    props.setSearchResults([...props.searchResults].map((value, index) => {
                            if (index === ind) {
                                value.best === true ? value.best = false : value.best = true;
                            } else {
                                value.best = null;
                            }
                            return value;
                        })
                    )
                }
            })
            .catch((error) => {
                console.log(error.data);
            })
    }

    const openCloseTreeView = (index) => {
        console.log(searchResults[index].tree_path);
        setTreeData(searchResults[index].tree_path);
        setIsModalOpen(isModalOpen => !isModalOpen);
    }
    const openCloseDocView = (data) => {
        console.log(data.vzta_documents);
        setDocModalData(data.vzta_documents);
        setIsModalOpen(isModalOpen => !isModalOpen);
    }
    const openCloseExportControlView = (data) => {
        setIsExportModalOpen(isExportModalOpen => !isExportModalOpen);
        setTaxNumber(data.tax_number);
    }
    
    const renderTable = () => {
        return (
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('approvalNumber', "Genehmigungsnummer")}</TableCell>
                        <TableCell>{t('description', "Beschreibung")}</TableCell>
                        <TableCell align="right">{t('validFrom', "Gültig ab")}</TableCell>
                        <TableCell align="right">{t('validTill', "Gültig bis")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {docModalData.map((row) => (
                        <TableRow
                            // key={row.name}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>{row.genehmigungsnummer}</TableCell>
                            <TableCell>
                                {row.description}
                            </TableCell>
                            <TableCell align="right">{Moment(row.valid_start).format('D.MM.YYYY')}</TableCell>
                            <TableCell align="right">{Moment(row.valid_end).format('D.MM.YYYY')}</TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        )
    }

    const renderBoldWords = (text, boldWordsArray) => {

        boldWordsArray.forEach((element) => {
            text = text.replace(element, `<strong style="color: #8C2E68;">${element}</strong>`);
        })
        let string = "<p style='margin: 0;'>" + text + "</p>";
        return (
            <div className="content" dangerouslySetInnerHTML={{__html: string}}></div>
        )
    }

    const bookmarkAdd = (tax_number) => {
        setSaveBookmark(tax_number);
    }
    const bookmarkDelete = (tax_number) => {
        BookmarkService.bookmarkDelete(tax_number)
            .then((response) => {
                console.log(response.status);
                if (response.status === 202) {
                    console.log(response.data.message);
                }
                props.setSearchResults([...props.searchResults].map((value, index) => {
                        if (value.tax_number === tax_number) {
                            console.log(value.tax_number);
                            value.is_bookmarked_by_user = false;
                        }
                        return value;
                    })
                )
            })
            .catch((error) => {
                // expired access token
                console.log(error);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            bookmarkDelete(tax_number);
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
    }
    const bookmarkNameChange = (e) => {
        setBookmarkName(e.target.value);
    }
    const bookmarkWithNameSave = () => {
        if (!saveBookmark) return false;
        BookmarkService.bookmarkCreate(saveBookmark, bookmarkName)
            .then((response) => {
                console.log(response.status);
                if (response.status === 202) {
                    console.log(response.data.message);
                }
                props.setSearchResults([...props.searchResults].map((value, index) => {
                        if (value.tax_number === saveBookmark) {
                            console.log(value.tax_number);
                            value.is_bookmarked_by_user = true;
                        }
                        return value;
                    })
                )
            })
            .catch((error) => {
                // expired access token
                console.log(error);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            bookmarkWithNameSave();
                            //bookmarkDelete(saveBookmark);
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            }).finally(() => {
            setSaveBookmark(false);
        })
    }
    const handleBookmarkNameModalClose = () => {
        setSaveBookmark(false);
    }

    const renderTopResultList = () => {
        return (
            <List dense={false}
                  sx={{
                      backgroundColor: backgroundMain,
                  }}>

                {searchResults.map((e, index) => {
                    if ((!(searchResults && searchResults[0].expected_relevancy_score >= 0.5) && index === 0) || ((searchResults && searchResults[0].expected_relevancy_score >= 0.5) && e.expected_relevancy_score > 0.5 && index <= 2)) {

                        if (props.selectedTaxNumber === e.tax_number) {
                            e.best = true;
                        } else {
                            e.best = null;
                        }

                        return (
                            <ListItem>
                                <TileRounded
                                    list
                                    data={e}
                                    index={index}
                                    openCloseTreeView={() => openCloseTreeView(index)}
                                    openCloseDocView={() => openCloseDocView(e)}
                                    renderBoldWords={renderBoldWords(e.matched_text, e.bold_words)}
                                    bookmarkAdd={() => bookmarkAdd(e.tax_number)}
                                    bookmarkDelete={() => bookmarkDelete(e.tax_number)}
                                    selectAsBestMatch={() => selectAsBestMatch(index, e.select_as_tax_number_url, e.tax_number)}
                                    openCloseExportControlView={() => openCloseExportControlView(e)}
                                />
                            </ListItem>
                        )
                    } else return false;
                })
                }
            </List>
        )
    }
    const renderTopResultMasonryTile = () => {
        return (
            <ImageList
                gap={22}
                cols={3}
                sx={{
                    backgroundColor: backgroundMain,
                    overflow: "hidden"
                }}
            >
                {searchResults.map((e, index) => {
                    if ((!(searchResults && searchResults[0].expected_relevancy_score >= 0.5) && index === 0) || ((searchResults && searchResults[0].expected_relevancy_score >= 0.5) && e.expected_relevancy_score > 0.5 && index <= 2)) {
                        if (props.selectedTaxNumber === e.tax_number) {
                            e.best = true;
                        } else {
                            e.best = null;
                        }

                        return (
                            <TileRounded
                                data={e}
                                index={index}
                                openCloseTreeView={() => openCloseTreeView(index)}
                                openCloseDocView={() => openCloseDocView(e)}
                                renderBoldWords={renderBoldWords(e.matched_text, e.bold_words)}
                                bookmarkAdd={() => bookmarkAdd(e.tax_number)}
                                bookmarkDelete={() => bookmarkDelete(e.tax_number)}
                                selectAsBestMatch={() => selectAsBestMatch(index, e.select_as_tax_number_url, e.tax_number)}
                                openCloseExportControlView={() => openCloseExportControlView(e)}
                            />
                        )
                    } else return false;
                })}
            </ImageList>
        )
    }
    const renderFurtherResultsList = () => {
        return (
            <List dense={false}
                  sx={{
                      backgroundColor: backgroundMain,
                  }}
            >
                {searchResults.map((e, index) => {

                    if ((!(searchResults && searchResults[0].expected_relevancy_score >= 0.5) && index === 0) || ((searchResults && searchResults[0].expected_relevancy_score >= 0.5) && e.expected_relevancy_score >= 0.5 && index <= 2)) {
                        return false;
                    }

                    if (props.selectedTaxNumber === e.tax_number) {
                        e.best = true;
                    } else {
                        e.best = null;
                    }

                    return (
                        <ListItem>
                            <TileRounded
                                list
                                data={e}
                                index={index}
                                openCloseTreeView={() => openCloseTreeView(index)}
                                openCloseDocView={() => openCloseDocView(e)}
                                renderBoldWords={renderBoldWords(e.matched_text, e.bold_words)}
                                bookmarkAdd={() => bookmarkAdd(e.tax_number)}
                                bookmarkDelete={() => bookmarkDelete(e.tax_number)}
                                selectAsBestMatch={() => selectAsBestMatch(index, e.select_as_tax_number_url, e.tax_number)}
                                openCloseExportControlView={() => openCloseExportControlView(e)}
                            />
                        </ListItem>
                    )
                })
                }
            </List>
        )
    }
    const renderFurtherResultsMasonry = () => {
        return (
            <ImageList
                cols={3}
                loading="lazy"
                gap={22}
                sx={{
                    backgroundColor: backgroundMain,
                    overflow: "hidden"
                }}
            >
                {searchResults.map((e, index) => {

                    if ((!(searchResults && searchResults[0].expected_relevancy_score >= 0.5) && index === 0) || ((searchResults && searchResults[0].expected_relevancy_score >= 0.5) && e.expected_relevancy_score >= 0.5 && index <= 2)) {
                        return false;
                    }

                    if (props.selectedTaxNumber === e.tax_number) {
                        e.best = true;
                    } else {
                        e.best = null;
                    }

                    return (
                        <TileRounded
                            data={e}
                            index={index}
                            openCloseTreeView={() => openCloseTreeView(index)}
                            openCloseDocView={() => openCloseDocView(e)}
                            renderBoldWords={renderBoldWords(e.matched_text, e.bold_words)}
                            bookmarkAdd={() => bookmarkAdd(e.tax_number)}
                            bookmarkDelete={() => bookmarkDelete(e.tax_number)}
                            selectAsBestMatch={() => selectAsBestMatch(index, e.select_as_tax_number_url, e.tax_number)}
                            openCloseExportControlView={() => openCloseExportControlView(e)}
                        />
                    )
                })
                }
            </ImageList>
        )
    }

    const ModalWindow = () => {
        return (
            <Modal
                sx={{
                    overflow: "scroll",
                    minHeight: "600px",
                    maxHeight: "100%",
                }}
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={style}
                >
                    <CloseIcon sx={{float: "right", cursor: "pointer"}} onClick={handleClose}/>
                    {docModalData.length > 0 &&
                    renderTable()
                    }
                    {treeData &&
                    <RichObjectTreeView
                        treeData={treeData}
                    />
                    }
                </Box>
            </Modal>
        )
    }

    const handleForeignCountryChange = (event) => {
        setDestinationCountry(event.target.value);
      };

      const linkToAccessToMarketPage = () => {
        const origin = 'DE';
        const destination = destinationCountry;
        const url = `https://trade.ec.europa.eu/access-to-markets/de/search?product=${taxNumber}&origin=${origin}&destination=${destination}`;
    
        window.open(url, '_blank');
      };

    const ExportControlModalWindow = () => {
        return (
            <Dialog
                open={isExportModalOpen}
                onClose={handleExportModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography sx={{
                        color: "#02615D",
                    }}
                        variant="h5"
                        component="div"
                        textAlign={"center"}>
                        {t("exportControl", "Export Kontrolle")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        disabled
                        value={taxNumber}
                        onChange={bookmarkNameChange}
                        label="Zolltariffnummer"
                        margin="normal"
                    />
                </DialogContent>
                <DialogContent>
                <Box sx={{ display: 'flex'}}>
                    <TextField
                        disabled
                        value="Deutschland"
                        label="Von"
                        style={{ marginRight: 40 }}
                        fullWidth
                    />
                    <FormControl fullWidth >
                        <InputLabel>Nach</InputLabel>
                        <Select
                            value={destinationCountry}
                            onChange={handleForeignCountryChange}
                            label="Nach"
                        >
                            {countries.map((country) => (
                                <MenuItem key={country.code} value={country.code}>
                                    {country.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        paddingRight: "20px",
                        paddingBottom: "0px"
                    }}>
                    <BasicButton
                        onClick={linkToAccessToMarketPage}
                        size="medium"
                        text={t("showResults", "Ergebnisse anzeigen")}
                    />
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <ModalWindow/>
            <ExportControlModalWindow/>
            <Dialog
                open={saveBookmark}
                onClose={handleBookmarkNameModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogTitle id="alert-dialog-title">
                    <Typography sx={{
                        // mt: 3.4,
                        // mb: 3,
                        // fontWeight: "bold",
                        color: "#02615D",
                    }}
                                variant="h5"
                                component="div"
                                textAlign={"center"}>
                        <StarOutlineIcon
                            sx={{
                                marginBottom: "-5px",
                                fontSize: "27px",
                            }}
                        /> {t("inputBookmarkName", "Merkliste eingeben (optional):")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        id="outlined-required"
                        value={bookmarkName}
                        onChange={bookmarkNameChange}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        paddingRight: "10px",
                        paddingBottom: "0px"
                    }}>
                    <BasicButton
                        startIcon={<HighlightOffIcon/>}
                        onClick={handleBookmarkNameModalClose}
                        size="small"
                        type="close"
                        text={t("cancel", "Abbrechen")}
                    />
                    <BasicButton
                        startIcon={<DoneIcon/>}
                        onClick={bookmarkWithNameSave}
                        size="small"
                        text={t("saveBookmark", "zur Merkliste hinzufügen")}
                    />
                </DialogActions>
            </Dialog>

            <Container
                maxWidth="xl"
                component="main">
                <CssBaseline/>

                {searchResults &&
                <Box sx={{
                    flexGrow: 1
                }}>
                    <Demo>
                        <List dense={false}
                              sx={{
                                  backgroundColor: backgroundMain,
                              }}>
                            <ListItem>
                                <ListItemText primary={false}/>
                                <ReorderIcon onClick={() => {
                                    setShowList(true)
                                }} sx={{cursor: 'pointer'}}/>
                                <GridViewIcon onClick={() => {
                                    setShowList(false)
                                }} sx={{cursor: 'pointer'}}/>
                            </ListItem>
                        </List>
                    </Demo>

                    <Typography
                        variant={'h6'}
                        sx={{
                            p: '10px 0 2px 3px',
                            display: 'inline-flex',
                            fontWeight: 'bold',
                            alignItems: 'center',
                            boxShadow: "none",
                            color: "#02615D",
                            backgroundColor: backgroundMain,
                        }}
                    >
                        <GppGoodIcon/>
                        {t('topResults')}:</Typography>

                    {showList
                        ?
                        renderTopResultList()
                        :
                        renderTopResultMasonryTile()
                    }
                    <Typography
                        variant={'h6'}
                        sx={{
                            p: '0 0 2px 3px',
                            display: 'inline-flex',
                            fontWeight: 'bold',
                            alignItems: 'center',
                            boxShadow: "none",
                            color: "#02615D",
                            backgroundColor: backgroundMain,
                        }}>
                        <HealthAndSafetyIcon/>
                        {t('furtherResults')}:</Typography>

                    {showList
                        ?
                        renderFurtherResultsList()
                        :
                        renderFurtherResultsMasonry()
                    }
                </Box>
                }
            </Container>
        </>
    );
}

export default SearchResults;
