import * as React from 'react';
import Button from '@mui/material/Button';

export default function BasicButton(props) {
  return (
      <Button
          disabled={props.disabled}
          startIcon={props.startIcon}
          type={props.type}
          variant={props.variant}
          fullWidth={props.fullWidth}
          onClick={props.onClick}
          size={props.size}
          style={props.style}
          sx={{
              mt: 3, mb: 2,
              paddingLeft: "15px",
              paddingRight: "15px",
              borderRadius: "0",
              border: props.disabled ? "1px solid" : "0",
              backgroundColor: props.disabled ? "white" : props.type === "close" ? '#9FD0C7' : props.type === "justText" ? "transparent" : "#02615D",
              color: props.type === "justText" ? "#02615D" : "white",
              textTransform: props.uppercase ? "uppercase" : "none",
              '&:hover': {
                  backgroundColor: props.type === "justText" ? "transparent" : '#9FD0C7',
              },
              marginTop: props.noMargin ? "15px" : "24px"
          }}
      >{props.text}</Button>
  );
}