import * as React from 'react';
import {useTranslation} from 'react-i18next'
import {useState} from 'react';

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import BasicButton from "../components/buttons/BasicButton";
import SuperLoader from "../components/SuperLoader";

import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import FeedbackPopUp from "../components/FeedbackPopUp";

const theme = createTheme();

const SignIn = (props) => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [severity, setSeverity] = useState("error");
    const [message, setMessage] = useState(undefined);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const [feedbackPopUp, setFeedbackPopUp] = useState(false);
    const [chosenSmile, setChosenSmile] = useState(undefined);
    const [feedbackText, setFeedbackText] = useState(undefined);

    const [newsArray, setNewsArray] = useState([]);
    const [newsAnswer, setNewsAnswer] = useState("");
    const [newsId, setNewsId] = useState(null);

    const feedbackPopupOpenClose = () => {
        // setFeedbackPopUp(!feedbackPopUp);
        window.location.reload();
        setLoading(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setEmailError(false);
        setPasswordError(false);

        const data = new FormData(event.currentTarget);
        let email = data.get('email');
        let password = data.get('password');

        if (!email) {
            setMessage(t("mandatoryFields", "Bitte füllen Sie alle geforderten Felder aus."));
            setEmailError(true);
        }
        if (!password) {
            setMessage(t("mandatoryFields", "Bitte füllen Sie alle geforderten Felder aus."));
            setPasswordError(true);
        }

        if(!email || !password) return;

        setLoading(true);
        // form.current.validateAll();

        // eslint-disable-next-line no-console
        console.log({
            email: email,
            password: password,
        });

        AuthService.login(email, password).then(
            (response) => {
                UserService.setToken(response);

                AuthService.checkForNewNews()
                    .then(
                        (response) => {
                            if(response.news.length > 0) {
                                console.log(response.news);
                                // setNewsText(response.news[response.news.length -1].text);
                                // setNewsHeader(response.news[response.news.length -1].news_header);
                                setNewsArray(response.news);
                                setNewsId(response.news[0].id);
                            }
                            else {
                                AuthService.checkForLastFeedbackDate()
                                    .then(
                                        (response) => {
                                            console.log(response.feedback);
                                            if(!response.feedback.created_on) {
                                                AuthService.createFeedback('first_login', 'first_login')
                                                    .then(function (response) {
                                                        console.log("The feedback was successfully sent");
                                                        console.log(response.data);
                                                    })
                                                    .catch((error) => {
                                                        console.log("Some error...");
                                                        console.log(error);
                                                    });
                                                window.location.reload();
                                                setLoading(false);
                                            }
                                            else if(response.feedback.feedback === 'first_login' || isMoreThenNinetyDaysPassed(response.feedback.created_on)) {
                                                setFeedbackPopUp(true);
                                            }
                                            else {
                                                window.location.reload();
                                                setLoading(false);
                                            }
                                        },
                                    )
                                    .then(() => {
                                        // window.location.reload();
                                        // setLoading(false);
                                    });
                            }
                        },
                    );
            },
            (error) => {
                if (error.response.data.detail === "No active account found with the given credentials") {
                    setMessage(t("noActiveUserWithGivenCredentials","Kein aktives Konto mit den angegebenen Anmeldeinformationen gefunden"));
                }
                else {
                    setMessage(error.response.data.detail);
                }
                setSeverity("error");
                console.log(error.response.data.detail);
                setLoading(false);
            }
        );
    };

    const feedbackPopupSubmit = () => {
        if(chosenSmile || feedbackText) {
            AuthService.createFeedback(chosenSmile, feedbackText)
                .then(function (response) {
                    console.log("The feedback was successfully sent");
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log("Some error...");
                    console.log(error);
                })
        }
        if(newsAnswer !== "") {
            AuthService.setNewsAnswer(newsId, newsAnswer)
                .then(function (response) {
                    console.log("The answer for the news " + newsId + " was successfully sent");
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log("Some error...");
                    console.log(error);
                })
        }
        setFeedbackPopUp(false);
        setNewsArray([]);
        setNewsId(null);
        setNewsAnswer("");

        // window.location.reload();
        setLoading(false);
    }

    const isMoreThenNinetyDaysPassed = (date) => {

        let date1 = new Date(date);
        let date2 = new Date();

        let Difference_In_Time = date2.getTime() - date1.getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        console.log(Difference_In_Days + " Days passed from last feedback");

        if(parseInt(Difference_In_Days) > 90) return true;
        return false;

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md">
                <Container component="main" maxWidth="xs">

                    <CssBaseline/>
                    {message && (
                        <Stack sx={{width: '100%', marginTop: 3}} spacing={2}>
                            <Alert
                                onClose={handleClose}
                                severity={severity}>{message}</Alert>
                        </Stack>
                    )}
                    <Box
                        sx={{
                            marginTop: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {!message &&
                        <>
                            <Avatar sx={{m: 1, bgcolor: '#02615D', mt: 1.5}}>
                                <LockOutlinedIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {t('signIn')}
                            </Typography>
                        </>
                        }
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <TextField
                                error={emailError}
                                margin="normal"
                                fullWidth
                                id="email"
                                label={t('emailAddress')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                error={passwordError}
                                margin="normal"
                                fullWidth
                                name="password"
                                label={t('password')}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />

                            {!loading &&
                            <BasicButton
                                size="large"
                                startIcon={<LoginIcon/>}
                                type="submit"
                                fullWidth
                                variant="contained"
                                text={t('signIn')}
                            />
                            }
                            {loading &&
                            <SuperLoader/>
                            }

                            <Box
                                sx={{
                                    paddingTop: "0",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >

                                <Link href="/password/reset/request" variant="bod2"
                                      sx={{
                                          color: "#7e8c96 !important",
                                          textDecoration: "none",
                                          cursor: "pointer",
                                      }}>

                                    {t('forgotPassword', 'Passwort vergessen?')}
                                </Link>



                            </Box>

                        </Box>
                    </Box>
                </Container>
                <hr
                    style={{
                        marginTop: "35px",
                        color: "#9FD0C7",
                        backgroundColor: "#9FD0C7",
                        height: 1
                    }}
                />
                <Container component="main" maxWidth="xs">

                    <Box
                        sx={{
                            paddingTop: "10px",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >

                        <Typography component="h1" variant="h6">
                            {t('dontHaveAnAccount')}
                        </Typography>

                    </Box>

                            <Link href="betaaccessrequest" variant="bod2"
                                  sx={{
                                      color: "#02615D!important",
                                      textDecoration: "none"
                                  }}>
                                <BasicButton
                                    size="large"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    startIcon={<PersonAddAltIcon/>}
                                    text={t('dontHaveAnAccount2')}
                                >
                                </BasicButton>
                            </Link>

                </Container>
                {(feedbackPopUp || newsArray.length > 0) &&
                <FeedbackPopUp
                    onClose={feedbackPopupOpenClose}
                    feedbackPopupOpenClose={feedbackPopupOpenClose}
                    feedbackPopupSubmit={feedbackPopupSubmit}
                    feedbackPopUp={feedbackPopUp}
                    chosenSmile={chosenSmile}
                    setChosenSmile={setChosenSmile}
                    feedbackText={feedbackText}
                    setFeedbackText={setFeedbackText}
                    newsAnswer={newsAnswer}
                    setNewsAnswer={setNewsAnswer}
                    setNewsId={setNewsId}
                    newsArray={newsArray}
                />
                }
            </Container>
        </ThemeProvider>
    );
}

export default SignIn;