import * as React from 'react';
import {styled} from "@mui/material/styles";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const RoundedButtonTemplate = styled(Button)((props) => ({
    margin: "2px",
    border: props.disabled ? "" : "1px solid",
    textTransform: "none",
    lineHeight: "1",
    padding: "8px",
    // height: "40px",
    borderRadius: "18px",
    backgroundColor: props.type === "main" ? "#02615D" : "white",
    color: props.type === "main" ? "white" : props.type === "secondary" ? "#02615D" : "white",
    borderColor: "#02615D",
    '&:hover': {
        backgroundColor: props.type === "main" ? "white" : "#02615D",
        color: props.type === "main" ? "#02615D" : "white"
    },
}));

const ButtonTextContainer = styled('span')`
    vertical-align: middle;
    margin: auto;
      text-align: center;
      display: inline-block;
`;

const ButtonIconContainer = styled('span')`
    vertical-align: middle;
    margin: auto;
      text-align: center;
      display: inline-block;
`;

export default function RoundedButton(props) {
    return (

        <Stack direction="row" spacing={1}>
            <RoundedButtonTemplate
                disabled={props.disabled}
                onClick={props.onClick}
                type={props.type}
                size={props.size}
                startIcon={props.icon}>
                {props.text}
            </RoundedButtonTemplate>
        </Stack>


        // <RoundedButtonTemplate
        //     type={props.type}
        //     onClick={props.onClick}
        //     size={props.size}
        // >
        //
        //
        //
        //     <ButtonIconContainer>
        //         {props.icon}
        //     </ButtonIconContainer>
        //     <ButtonTextContainer>
        //         {props.text}
        //     </ButtonTextContainer>
        // </RoundedButtonTemplate>
    );
}