import * as React from 'react';
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from "react";

import AuthService from "../services/auth.service";

import SuperLoader from "../components/SuperLoader";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import TextField from '@mui/material/TextField';
import ErrorHandler from "../services/error.handler";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import BasicButton from "../components/buttons/BasicButton";
import {styled} from "@mui/material/styles";

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    // color: white;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #C0C0C0;
    }
  }
`;

const ButtonContainer = styled("div")`
    text-align: center;
  `;

export default function Profile() {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({})
    const [updatingStarted, setUpdatingStarted] = useState(false);
    const [message, setMessage] = useState(undefined);
    const [severity, setSeverity] = useState("error");

    useEffect(() => {
        loadUser();
    }, []);

    const updateUser = async () => {
        setUpdatingStarted(true);
        setMessage(undefined);
        AuthService.isAccessTokenStillAlive()
            .then(function (response) {
                // console.log(response);
                AuthService.updateUser(userData)
                    .then((response) => {
                        console.log(response);

                        setUpdatingStarted(false);
                        setSeverity("success");
                        setMessage("successfully updated!");
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        console.log(error.response.data);

                        setUpdatingStarted(false);
                        setSeverity("error");
                        setMessage(error.response.data);
                    });

            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    // console.log(error.response.headers);
                    console.log("Access token has expired. Refreshing...");
                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                        AuthService.refreshAccessToken()
                            .then(() => {
                                console.log("Starting the main function again...activateDeactivateUser");
                                updateUser();
                            })
                            .catch((error) => {
                                // expired refresh token => logout
                                ErrorHandler.handleError(error);
                                console.log("Refresh token has expired. Logout...");
                                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                    AuthService.logout();
                                    // navigate('/');
                                    window.location.reload();
                                }
                            });
                    } else if (error.response.status === 403) {
                        // navigate('/');
                        window.location.reload();
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    setMessage('Error: ' + error.message);
                    setUpdatingStarted(false);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    setMessage('Error' + error.message);
                    setUpdatingStarted(false);
                }
                console.log(error.config);
            });
    };

    const loadUser = async () => {
        setLoading(true);
        AuthService.getCurrentUserData()
            .then(function (response) {
                // handle success
                console.log(response.data);
                setUserData(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
                setLoading(false);
            })
    };

    const changeField = e => {
        console.log(e.target.id);
        console.log(e.target.value);

        const {name, value} = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleStackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
    };

    return (
        <Container component="main" maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5" component="div"
                                textAlign={"center"}>
                        <CorporateFareIcon
                            sx={{
                                marginBottom: "-5px",
                                fontSize: "30px",
                                paddingRight: "4px"
                            }}
                        />
                        {t('userData', "Benutzerdaten")}:
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
                {loading || updatingStarted && <SuperLoader/>}
                {message && (
                    <Stack sx={{width: '100%', marginTop: 4.4}} spacing={2}>
                        <Alert
                            onClose={handleStackClose}
                            severity={severity}>{message}</Alert>
                    </Stack>
                )}
            </Grid>

            <Grid item xs={8}>
                <Card sx={{minWidth: 275}}>
                    <CardContent>
                        <Box

                            component="form"
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <WhiteBorderTextField
                                    id="name"
                                    name="name"
                                    label="Name"
                                    fullWidth
                                    value={userData.name}
                                    onChange={changeField}
                                    focused
                                />
                            </div>
                            <br/>
                            <div>
                                <WhiteBorderTextField
                                    required
                                    id="email"
                                    name="email"
                                    // label="Email"
                                    value={userData.email}
                                    onChange={changeField}
                                    fullWidth
                                    focused
                                    disabled
                                />
                            </div>
                            <br/>
                            <div>
                                <WhiteBorderTextField
                                    required
                                    id="company_name"
                                    name="company_name"
                                    label="Organisation"
                                    value={userData.company_name}
                                    onChange={changeField}
                                    fullWidth
                                    focused
                                />
                            </div>
                            <br/>
                            <div>
                                <WhiteBorderTextField
                                    required
                                    id="company_address"
                                    name="company_address"
                                    label="Adresse"
                                    value={userData.company_address}
                                    onChange={changeField}
                                    fullWidth
                                    focused
                                />
                            </div>
                            <br/>
                            <div>
                                <WhiteBorderTextField
                                    required
                                    id="billing_address"
                                    name="billing_address"
                                    label="Rechnungsadresse"
                                    value={userData.billing_address}
                                    onChange={changeField}
                                    fullWidth
                                    focused
                                />
                            </div>
                        </Box>
                    </CardContent>
                </Card>
                <ButtonContainer>
                    <BasicButton
                        type="submit"
                        variant="contained"
                        text={t("Speichern")}
                        noMargin={true}
                        style={{padding: 15, width: 200}}
                        onClick={() => updateUser()}
                    />
                </ButtonContainer>
            </Grid>

        </Container>

    );
}
