import * as React from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';


export default function RichObjectTreeView(props) {

    // console.log(props.treeData);
    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name + " - " + nodes.value }>
            {/*<TreeItem key={nodes.id + 1} nodeId={nodes.id + 1} label={nodes.value}>*/}
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
            {/*</TreeItem>*/}
        </TreeItem>
    );

    return (
        <TreeView
            aria-label="rich object"
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpanded={["root",1,2,3,4,5,6,7,8,9,0,10,11,12,13,14,15]}
            defaultExpandIcon={<ChevronRightIcon/>}
            sx={{flexGrow: 1, overflowY: 'auto'}}
        >
            {renderTree(props.treeData)}
        </TreeView>
    );
}