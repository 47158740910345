import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom"
import {useTranslation} from 'react-i18next'
import {useNavigate} from "react-router-dom";

import AuthService from "../services/auth.service";
import RateService from "../services/rate.service";
import ErrorHandler from "../services/error.handler";

import BasicButton from "../components/buttons/BasicButton";
import SuperLoader from "../components/SuperLoader";
import TierPlanTile from "../components/TierPlanTile";
import RateTable from "../components/RateTable";

import Alert from "@mui/material/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Masonry from "@mui/lab/Masonry";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import {styled} from '@mui/material/styles';
import DialogContentText from '@mui/material/DialogContentText';

import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CloseIcon from "@mui/icons-material/Close";

const DatenschutzLink = styled(Link)`
  margin-right: 6px;
  margin-left: 6px;
  color: #7e8c96 !important;
  text-decoration: none;
  display: inline;
  &:hover {
    color: #7e8c96;
  }
`;
const Plan = (props) => {

    const modalStyle = {
        margin: "50px auto",
        maxWidth: "70%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        border: "0"
    };


    const {
        me,
        checkIfUserStillLoggedIn,
        loggedIn,
    } = props;

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [newPlanData, setNewPlanData] = useState(
        {
            "is_active": true,
            "price_euro": 0,
            "period_months": 12,
            "name": "New rate",
            "features": [
                {
                    name: "feature1",
                    description: "feature1 description"
                },
                {
                    name: "feature2",
                    description: "feature2 description"
                },
                {
                    name: "feature2",
                    description: "feature2 description"
                }
            ]
        }
    );
    const [editedPlanData, setEditedPlanData] = useState(
        {
            "id": undefined,
            "is_active": false,
            "price_euro": 0,
            "period_months": 12,
            "name": "New rate",
            "features": []
        });
    const [currentUserRate, setCurrentUserRate] = useState(null);
    const [planIdToEdit, setPlanIdToEdit] = useState(undefined);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [rateList, setRateList] = useState([]);
    const [featureList, setFeatureList] = useState([]);

    const [preSelectedRateForDialog, setPreSelectedRateForDialog] = useState(undefined);
    const [isPreSelectedRateForDialogCustom, setIsPreSelectedRateForDialogCustom] = useState(undefined);

    const [chosenRateIdForDialog, setChosenRateIdForDialog] = useState();
    const [isChosenRateIdForDialogCustom, setIsChosenRateIdForDialogCustom] = useState(false);
    const [chosenRateId, setChosenRateId] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        loadRateList();
    }, []);

    const loadRateList = async () => {

        loadRateListForUser();

        setLoading(true);
        setMessage(null);
        setCurrentUserRate(null);
        AuthService.getCurrentUserData()
            .then(function (response) {
                console.log(me.email);
                if (!loggedIn) {
                    checkIfUserStillLoggedIn();
                }
                RateService.listTiers()
                    .then(function (response) {
                        console.log(response.data);
                        setRateList(response?.data);
                        RateService.getUserRate(me.id)
                            .then(function (response) {
                                console.log(response.data);
                                setCurrentUserRate(response.data.rate);
                            })
                            .catch((error) => {
                                console.log(error);
                                setCurrentUserRate(null);
                            });
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        // expired access token
                        // ErrorHandler.handleError(error);
                        console.log("Access token has expired. Refreshing...");
                        if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                            AuthService.refreshAccessToken()
                                .then(() => {
                                    loadRateList();
                                })
                                .catch((error) => {
                                    // expired refresh token => logout
                                    ErrorHandler.handleError(error);
                                    console.log("Refresh token has expired. Logout...");
                                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                        AuthService.logout();
                                        window.location.reload();
                                    }
                                });
                        } else if (error.response.status === 403) {
                            navigate('/');
                            window.location.reload();
                        }
                    })
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            loadRateList();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            ErrorHandler.handleError(error);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                } else {
                    console.log(error);
                }
            })
            .finally(() => {
                console.log('Finally completed');
            });
    };

    const loadRateListForUser = async () => {

        RateService.getFeatureList()
            .then(function (response) {
                console.log(response.data);
                setFeatureList(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                // expired access token
                // ErrorHandler.handleError(error);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            loadRateListForUser();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            ErrorHandler.handleError(error);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
    }

    const deleteRate = (id) => {
        // alert("deleting");
        RateService.deleteTier(id)
            .then((response) => {
                console.log(response.status);
                if (response.status === 202) {
                    console.log(response.data.message);
                }
            })
            .catch((error) => {
                // expired access token
                console.log(error);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            deleteRate(id);
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
        loadRateList();
    }

    const updateTier = (id) => {

        setIsModalOpen(true);
        setPlanIdToEdit(id);

        rateList.forEach((value, index) => {
            if (value.id === id) {
                setEditedPlanData(rateList[index]);
            }
        })
    }

    const saveNewPlan = () => {
        AuthService.getCurrentUserData()
            .then(function (response) {
                console.log(newPlanData);
                RateService.createTier(newPlanData)
                    .then((response) => {
                        console.log(response.status);
                        handleModalClose();
                        if (response.status === 202) {
                            console.log(response.data.message);
                        }
                    })
                    .catch((error) => {
                        // expired access token
                        console.log(error);
                        console.log(error.response.data.code);
                        console.log("Access token has expired. Refreshing...");
                        if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                            AuthService.refreshAccessToken()
                                .then(() => {
                                    saveNewPlan();
                                })
                                .catch((error) => {
                                    // expired refresh token => logout
                                    console.log(error.response.status);
                                    console.log(error.response.data.code);
                                    console.log("Refresh token has expired. Logout...");
                                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                        AuthService.logout();
                                        // navigate('/');
                                        window.location.reload();
                                    }
                                });
                        } else if (error.response.status === 403) {
                            navigate('/');
                            window.location.reload();
                        }
                    })
                handleModalClose();
                loadRateList();
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            handleModalClose();
                            loadRateList();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            ErrorHandler.handleError(error);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                } else {
                    console.log(error);
                }
            })
            .finally(() => {
                console.log('Finally completed');
            });
    }

    const saveUpdatedTier = () => {
        console.log(editedPlanData);
        setIsModalOpen(false);
        RateService.updateTier(editedPlanData)
            .then((response) => {
                console.log(response.status);
                if (response.status === 202) {
                    console.log(response.data.message);
                }
            })
            .catch((error) => {
                // expired access token
                console.log(error);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            updateTier(id);
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
        loadRateList();
    }

    function rateElement(element) {
        if (!props.me.is_admin && !element.is_active) return false;

        return (
            <TierPlanTile
                data={element}
                updateTier={() => updateTier(element.id)}
                me={props.me}
                currentUserRate={currentUserRate}
                selectPlan={() => selectPlan(element.id)}
            />
        );
    }

    const handleMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setPlanIdToEdit(undefined);
        // console.log(editedPlanData);
    }

    const addNewPlan = () => {
        setIsModalOpen(true);
        setPlanIdToEdit("new");
    }

    const handleChangeNewPlanField = (event) => {
        console.log(event.target);
        switch (event.target.name) {
            case 'name':
                setNewPlanData({...newPlanData, name: event.target.value});
                break;
            case 'price_euro':
                setNewPlanData({...newPlanData, price_euro: event.target.value});
                break;
            case 'period_months':
                setNewPlanData({...newPlanData, period_months: event.target.value});
                break;
            case 'is_active':
                setNewPlanData({...newPlanData, is_active: event.target.value});
                break;
        }
    }

    const handleChangeEditPlanField = (event) => {
        switch (event.target.name) {
            case 'name':
                setEditedPlanData({...editedPlanData, name: event.target.value});
                break;
            case 'price_euro':
                setEditedPlanData({...editedPlanData, price_euro: event.target.value});
                break;
            case 'period_months':
                setEditedPlanData({...editedPlanData, period_months: event.target.value});
                break;
            case 'is_active':
                setEditedPlanData({...editedPlanData, is_active: event.target.checked});
                break;
        }
    }

    const addFeature = (e) => {
        // alert(e.target.value);
    }

    const renderPlanEdit = () => {
        return (
            <>
                <TextField
                    onChange={handleChangeEditPlanField}
                    name="name"
                    helperText={t("selectRateName", "Bitte wählen Sie einen Namen für die Lizenz.")}
                    label="Name"
                    value={editedPlanData.name}
                    color="secondary"
                    fullWidth/>
                <br/><br/>
                <TextField
                    onChange={handleChangeEditPlanField}
                    name="price_euro"
                    helperText={t("selectRatePrice", "Bitte wählen Sie einen Preis.")}
                    label={t("PriceEuro", "Preis (€)")}
                    value={editedPlanData.price_euro}
                    color="secondary"
                    fullWidth/>
                <br/><br/>
                <TextField
                    onChange={handleChangeEditPlanField}
                    name="period_months"
                    helperText={t("selectRatePeriod", "Bitte wählen Sie eine Zeitspanne für die regelmäßige Abrechnung.")}
                    label={t("periodMonths", "Zeitspanne (in Monaten)")}
                    value={editedPlanData.period_months}
                    color="secondary"
                    fullWidth/>
                <br/>
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={handleChangeEditPlanField}
                            name="is_active"
                            checked={editedPlanData.is_active}/>}
                    label="Active"/>
                <br/><br/>
                <Typography>{t("features", "Bestandteile")}</Typography>
                {
                    editedPlanData.features.map((value, index, array) => {
                        return (
                            <>
                                <TextField value={value} label="feature" color="secondary" fullWidth/>
                                <br/>
                                <br/>
                            </>
                        );
                    })
                }
                <button
                    onClick={() => {
                        addFeature
                    }}
                >add feature
                </button>
                <br/><br/>
                <Button
                    onClick={saveUpdatedTier}
                    variant="contained"
                    color="primary">
                    Speichern
                </Button>
            </>
        );

    }

    const renderPlanNew = () => {
        return (
            <>
                <TextField
                    onChange={handleChangeNewPlanField}
                    name="name"
                    value={newPlanData.name}
                    helperText={t("selectRateName", "Bitte wählen Sie einen Namen für die Lizenz.")}
                    label={t("name", "Name")}
                    color="secondary"
                    fullWidth/>
                <br/><br/>
                <TextField
                    onChange={handleChangeNewPlanField}
                    name="price_euro"
                    value={newPlanData.price_euro}
                    helperText={t("selectRatePrice", "Bitte wählen Sie einen Preis.")}
                    label={t("PriceEuro", "Preis (€)")}
                    color="secondary"
                    fullWidth/>
                <br/><br/>
                <TextField
                    onChange={handleChangeNewPlanField}
                    name="period_months"
                    value={newPlanData.period_months}
                    helperText={t("selectRatePeriod", "Bitte wählen Sie eine Zeitspanne für die regelmäßige Abrechnung.")}
                    label={t("periodMonths", "Zeitspanne (in Monaten)")}
                    color="secondary"
                    fullWidth/>
                <br/>
                <FormControlLabel
                    control={<Checkbox/>}
                    onChange={handleChangeNewPlanField}
                    name="is_active"
                    label={t("Active", "Aktiv")}
                    value={newPlanData.is_active}/>
                <br/><br/>
                <Typography>{t("features", "Bestandteile")}:</Typography>
                <br/><br/>
                <Button
                    onClick={saveNewPlan}
                    variant="contained"
                    color="primary">
                    {t("Save", "Speichern")}
                </Button>
            </>
        );
    }

    const handleClickSelectRateButton = (id, is_custom, name) => {
        console.log(name);
        setPreSelectedRateForDialog(id);
        setIsPreSelectedRateForDialogCustom(is_custom);
        if (name.toLowerCase() === "premium") {
            setIsPreSelectedRateForDialogCustom(true);
        }
        setOpenDialog(true);
    };

    const dialogButtonAcceptClick = async () => {
        // alert(preSelectedRateForDialog);
        await selectPlan();
        handleCloseDialog();
    }

    const dialogButtonCancelClick = () => {
        setPreSelectedRateForDialog(undefined);
        setIsPreSelectedRateForDialogCustom(false);
        handleCloseDialog();
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setChosenRateIdForDialog(null);
        setIsChosenRateIdForDialogCustom(false);
    };

    const selectPlan = async () => {

        RateService.getUserRate(me.id)
            .then(function (response) {
                console.log(response.data);
                if (me.is_admin) {
                    RateService.deleteUserRate(me.id)
                        .then(function (response) {
                            console.log(response.data);
                            let data = {
                                "end_date": null,
                                "is_paid": false,
                                "user": me.id,
                                "rate": chosenRateId
                            }
                            RateService.createUserRate(data)
                                .then(function (response) {
                                    console.log(response.data);
                                    loadRateList();
                                })
                                .catch((error) => {
                                    console.log(error.response.status);
                                })
                        })
                        .catch((error) => {
                            console.log(error.response.status);
                        })
                } else {
                    setMessage(t("onlyAdminCanUpdateRatePlan", "Derzeit kann nur ein Administrator Ihren Abonnementplan upgraden!"));
                }
            })
            .catch((error) => {
                console.log(error.response.status);
                if (error.response.status === 404) {
                    let data = {
                        "end_date": null,
                        "is_paid": false,
                        "user": me.id,
                        "rate": preSelectedRateForDialog
                    }
                    RateService.createUserRate(data)
                        .then(function (response) {
                            console.log(response.data);
                            loadRateList();
                        })
                        .catch((error) => {
                            console.log(error.response.status);
                        })
                }
            })
    }

    const renderRateTableForUser = () => {

        return (
            <>
                <RateTable
                    disableLogo
                    openDialog={openDialog}
                    rateList={rateList}
                    featureList={featureList}
                    handleClickSelectRateButton={handleClickSelectRateButton}
                    isPreSelectedRateForDialogCustom={isPreSelectedRateForDialogCustom}
                    dialogButtonAcceptClick={dialogButtonAcceptClick}
                    dialogButtonCancelClick={dialogButtonCancelClick}
                    chosenRateId={currentUserRate}
                />
            </>
        );
    }

    const renderRateTableForAdmin = () => {
        return (
            <Box sx={{flexGrow: 1}}>
                <Masonry columns={3} spacing={1}
                         sx={{margin: 0, marginTop: 3}}
                >
                    {rateList.map(rateElement)}
                </Masonry>
            </Box>
        )
    }

    const renderModal = () => {

        return (
            <Modal
                sx={{
                    overflow: "scroll",
                    minHeight: "600px",
                    maxHeight: "100%",
                }}
                open={isModalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {planIdToEdit &&
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={modalStyle}
                >
                    <CloseIcon sx={{float: "right", cursor: "pointer"}} onClick={handleModalClose}/>
                    {(planIdToEdit !== "new") &&
                    renderPlanEdit()
                    }

                    {(planIdToEdit === "new") &&
                    renderPlanNew()
                    }
                </Box>
                }

            </Modal>
        );

    }

    const renderHeader = () => {

        return (
            <>
                <Typography sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5"
                            component="div"
                            textAlign={"center"}>
                    <AccountBalanceWalletIcon
                        sx={{
                            marginBottom: "-5px",
                            fontSize: "30px",
                        }}
                    />
                    {t('ratePlanListUserHeader1', "Lizenzen")}
                </Typography>
                <Typography
                    sx={{
                        mt: 3.4, mb: 3,
                        margin: "auto",
                        maxWidth: "70%"
                    }}
                    variant="h6" component="div"
                    textAlign={"center"}>
                    {t('ratePlanListUserSubHeader', "Ein transparentes Angebot - ohne versteckte Kosten. " +
                        "Wählen Sie das für sich passende Angebot, monatlich kündbar. Bei Fragen stehen wir Ihnen gerne zu Verfügung.")
                    }
                </Typography>
            </>
        )

    }

    const renderMessage = () => {
        return (
            <Stack sx={{width: '100%', marginTop: 4.4}} spacing={2}>
                <Alert
                    onClose={handleMessageClose}
                    severity={severity}>{message}</Alert>
            </Stack>
        )
    }

    const renderAddRateButton = () => {
        return (
            <Button
                onClick={addNewPlan}
                size="small"
                sx={{
                    padding: "6px 10px 3px 10px",
                    marginLeft: "29px",
                    borderRadius: "0",
                    backgroundColor: "#02615D",
                    color: "white",
                    textTransform: "none",
                    '&:hover': {
                        // backgroundColor: '#8a0856',
                        backgroundColor: '#9FD0C7',
                    },
                }}
            >
                {t('LicenseAdd', 'Neue Lizenz hinzufügen')}
            </Button> //todo
        )
    }

    const RateDialog = () => {

        const [isDataprivacyCheckboxChecked, setIsDataprivacyCheckboxChecked] = useState(false);
        const [dataprivacyMessage, setDataprivacyMessage] = useState();

        const checkedChange = (event) => {
            setDataprivacyMessage(null);
            setIsDataprivacyCheckboxChecked(event.target.checked);
        }

        const selectRateButtonClick = () => {
            isDataprivacyCheckboxChecked ? selectCustomRate() : setDataprivacyMessage(t("datePrivacyMessage", "Die Bestätigung der Datenschutzbestimmungen ist notwendig."));
        }

        return (
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: 0,
                        paddingRight: "15px",
                        paddingTop: "20px",
                        paddingLeft: "10px"
                    }
                }}
                open={false}
                keepMounted
                // onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {isChosenRateIdForDialogCustom &&
                        <strong>
                            {t('thanksForYourInterest', 'Danke für Ihr Interesse. Diese Lizenz ist noch nicht verfügbar, aber wir werden Sie so schnell wie möglich informieren.')}
                        </strong>
                        }
                        {!isChosenRateIdForDialogCustom &&
                        <strong>
                            {t('confirmDataPrivacyFirst', 'Bitte bestätigen Sie den Datenschutzbestimmungen und Datenschutzerklärung:')}
                        </strong>
                        }
                        <br/>
                        {!isChosenRateIdForDialogCustom &&
                        <>
                            <FormControlLabel control={
                                <Checkbox
                                    onChange={checkedChange}
                                    checked={isDataprivacyCheckboxChecked}/>}
                                              label={t("datePrivacyAccept", "Ich bestätige hiermit die ")}/>
                            <DatenschutzLink to={'/AGB.pdf'}
                                             target="_blank">{t("privacypolicy", "Datenschutzbestimmungen. ")}</DatenschutzLink>
                            {dataprivacyMessage && <div style={{color: 'red'}}>{dataprivacyMessage}</div>}
                        </>
                        }
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <BasicButton
                        onClick={handleCloseDialog}
                        type={"close"}
                        startIcon={<HighlightOffIcon/>}
                        text={t("close", "Schließen")}
                    />
                    {!isChosenRateIdForDialogCustom &&
                    <BasicButton
                        onClick={selectRateButtonClick}
                        startIcon={<DoneIcon/>}
                        text={t("accept", "Akzeptieren")}
                    />
                    }
                </DialogActions>
            </Dialog>
        )
    }

    const selectCustomRate = async () => {
        handleCloseDialog();
        await selectPlan(chosenRateIdForDialog);
        setChosenRateIdForDialog(null);
    }

    return (
        <>
            {me.is_admin && renderModal()}
            <RateDialog/>

            <Container
                maxWidth="xl"
                component="main">
                <CssBaseline/>

                {renderHeader()}
                {message && renderMessage()}
                {loading && <SuperLoader/>}


                {props.me.is_admin && renderAddRateButton()}

                {!loading &&
                <Container
                    maxWidth="xl"
                    component="main">

                    <CssBaseline/>

                    {me.is_admin && rateList &&
                    renderRateTableForAdmin()
                    }
                    {!me.is_admin && rateList && renderRateTableForUser()}
                </Container>
                }
            </Container>
        </>
    );
};

export default Plan;
