import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import MoodBadIcon from '@mui/icons-material/MoodBad';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';

import {styled} from "@mui/material/styles";
import TextField from '@mui/material/TextField';
import BasicButton from "./buttons/BasicButton";
import LoginIcon from "@mui/icons-material/Login";
import {useTranslation} from "react-i18next";
import {useState} from "react";

const SmileyContainer = styled('div')`
     padding-top: 15px;
     display: flex;
     // flex-direction: column;
     align-items: center;
`;

const ButtonContainer = styled('div')`
     display: flex;
     flex-direction: row;
     justify-content: space-between;
`;

const Smiley = styled('div')((props) => ({
    textShadow: props.active ? "0 0 10px #02615D" : "",
    fontSize: "2em",
    margin: "auto",
    cursor: "pointer",
    color: "#CCCC00",
    '&:hover': {
        textShadow: "0 0 15px #02615D",
        color: "#CCCC00",
    },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function FeedbackPopUp(props) {

    const {
        onClose,
        feedbackPopupOpenClose,
        feedbackPopupSubmit,
        feedbackPopUp,
        newsArray,
        chosenSmile,
        setChosenSmile,
        feedbackText,
        setFeedbackText,
        newsAnswer,
        setNewsAnswer,
        setNewsId
    } = props;

    const {t} = useTranslation();
    const [open, setOpen] = React.useState(true);

    const feedbackTextChange = (e) => {
        setFeedbackText(e.target.value);
    }
    const newsAnswerChange = (e) => {
        setNewsAnswer(e.target.value);
    }

    const renderNews = (value) => {

        if(value.need_answer) {
            setNewsId(value.id);
        }
            return (
                <>
                    <Typography sx={{textTransform: 'uppercase'}} id="modal-modal-title" variant="h6" component="h2">
                        {value.news_header}
                    </Typography>
                    <Typography sx={{marginBottom: "5px"}} id="modal-modal-title" component="h4">
                        {value.text}
                    </Typography>
                    {value.need_answer &&
                    <TextField
                        margin="normal"
                        fullWidth
                        name="news_answer"
                        label={t('Answer')}
                        id="answer"
                        autoFocus
                        value={newsAnswer}
                        onChange={newsAnswerChange}
                    />
                    }
                </>
            );
    }
    return (
        <div>
            <Modal
                disableEnforceFocus
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {newsArray.length > 0 &&
                        <>
                            <Typography sx={{fontWeight: "bolder"}}  id="modal-modal-title" variant="h5" component="h2">
                                Was gibt's Neues:
                            </Typography>
                        <br/>
                        </>
                    }
                    {newsArray.length > 0 && newsArray.map(renderNews)}
                    {feedbackPopUp &&
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t('feedback', 'Wie gefällt Ihnen Custify? Wir würden uns über Ihr Feedback freuen:')}
                        </Typography>

                        <SmileyContainer>
                            <Smiley active={chosenSmile === 'sad'} onClick={() => setChosenSmile('sad')}>
                                &#128548;
                            </Smiley>
                            <Smiley active={chosenSmile === 'unsatisfied'} onClick={() => setChosenSmile('unsatisfied')}>
                                &#128549;
                            </Smiley>
                            <Smiley active={chosenSmile === 'neutral'} onClick={() => setChosenSmile('neutral')}>
                                &#128528;
                            </Smiley>
                            <Smiley active={chosenSmile === 'satisfied'} onClick={() => setChosenSmile('satisfied')}>
                                &#128522;
                            </Smiley>
                            <Smiley active={chosenSmile === 'happy'} onClick={() => setChosenSmile('happy')}>
                                &#128525;
                            </Smiley>
                        </SmileyContainer>

                        <TextField
                            margin="normal"
                            fullWidth
                            name="feedback"
                            label={t('Text')}
                            id="email"
                            autoFocus
                            value={feedbackText}
                            onChange={feedbackTextChange}
                        />
                    </>
                    }

                    <ButtonContainer>
                        <BasicButton
                            fullWidth
                            startIcon={<ClearIcon/>}
                            type="close"
                            variant="contained"
                            text={t('close')}
                            onClick={feedbackPopupOpenClose}
                        />
                        &nbsp;
                        <BasicButton
                            fullWidth
                            startIcon={<CheckCircleOutlineIcon/>}
                            type="submit"
                            variant="contained"
                            text={t('OK')}
                            onClick={feedbackPopupSubmit}
                        />
                    </ButtonContainer>
                </Box>
            </Modal>
        </div>
    );
}