import axios from "axios";
import AuthService from "./auth.service";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_NEWSBOX_ADD = API_URL + "/news/";
const API_URL_NEWSBOX_LIST = API_URL + "/news/";
const API_URL_NEWSBOX_DELETE = API_URL + "/news/";
const API_URL_NEWSBOX_UPDATE = API_URL + "/news/";

// Add a request interceptor
const axios_search = axios.create();
axios_search.interceptors.request.use(function (config) {
  let token = AuthService.getCurrentUser();
  config.headers.Authorization = "JWT " + token.access;
  return config;
});

const getNewsBoxList = () => {
  let token = AuthService.getCurrentUser();
  return axios.get(API_URL_NEWSBOX_LIST, {
    headers: {
      Authorization: "JWT " + token.access,
    },
  });
};

const createNewsBox = (data) => {
  let token = AuthService.getCurrentUser();
  return axios.post(API_URL_NEWSBOX_ADD, data, {
    headers: {
      Authorization: "JWT " + token.access,
    },
  });
};

const updateNewsBox = (id, data) => {
  let token = AuthService.getCurrentUser();
  return axios.put(API_URL_NEWSBOX_UPDATE + id + "/", data, {
    headers: {
      Authorization: "JWT " + token.access,
    },
  });
};

const deleteNewsBox = (id) => {
  let token = AuthService.getCurrentUser();
  return axios.delete(API_URL_NEWSBOX_DELETE + id + "/", {
    headers: {
      Authorization: "JWT " + token.access,
    },
  });
};

export default {
  getNewsBoxList,
  createNewsBox,
  deleteNewsBox,
  updateNewsBox,
};
