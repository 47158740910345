import React from "react";
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from "react";
import AuthService from "../../services/auth.service";
import SearchService from "../../services/search.service";
import SearchResults from "../../components/SearchResults";
import History from "../../pages/history";

import Moment from 'moment';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import SuperLoader from "../../components/SuperLoader";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import {useNavigate} from "react-router-dom";

import Switch from "@mui/material/Switch";

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';


const supportList = () => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [dense, setDense] = useState(false);
    const [showUsersWithZeroSearches, setShowUsersWithZeroSearches] = useState(false);

    const [searchHistoryList, setSearchHistoryList] = useState([]);
    const [currentSearch, setCurrentSearch] = useState("");
    const [searchOpened, setSearchOpened] = useState();
    const [searchForUser, setSearchForUser] = useState(false);
    const [searchForUserOpened, setSearchForUserOpened] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const [clickSecondStep, setClickSecondStep] = useState(false);
    const Demo = styled('div')(({theme}) => ({
        backgroundColor: theme.palette.background.paper,
        // backgroundColor: "red",
    }));

    const navigate = useNavigate();

    useEffect(() => {
        loadSearchHistoryPerUser();
    }, []);

    const loadSearchHistoryPerUser = async () => {
        setLoading(true);
        await SearchService.listUsersAndSearchMetadata()
            .then(function (response) {
                // console.log(response.data);
                setSearchHistoryList(response?.data);
                setLoading(false);
            })
            .catch((error) => {
                // expired access token
                console.log(error.response.status);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            loadSearchHistoryPerUser();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })

    };

    const openSearchComponent = (el) => {
        console.log(el.searches_url);
        setActiveStep(1);
        setSearchForUserOpened(true);
        setSearchForUser(el.searches_url);
    }

    const handleStep = (index) => {
        setActiveStep(index);
        if (index === 0) {
            setSearchOpened(false);
            setSearchForUserOpened(false);
            setCurrentSearch("");
        }
        if (index === 1) {
            setClickSecondStep(true);
            setSearchOpened(false);
            setSearchForUserOpened(true);
            setCurrentSearch("");
        }
    }

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
    };

    const showHideNullSearches = () => {
        setShowUsersWithZeroSearches((showUsersWithZeroSearches) => !showUsersWithZeroSearches)
    }

    function searchElement(element, index) {
        if (!showUsersWithZeroSearches && element.count_searches === 0) {
            return null;
        }
        let secondaryText = "";
        if (element.most_recent_search_time) {
            secondaryText = t('lastSearch') + ': "' + element.most_recent_search_input + '" ' + Moment(element.most_recent_search_time).format('D.MM.YYYY');
        }

        return (
            <ListItem
                sx={{
                    "&:hover": {
                        backgroundColor: "#f6f6f6",
                        cursor: "pointer"
                    }
                }}
                onClick={() => openSearchComponent(element)}
                secondaryAction={
                    <Typography>
                        {t('Searches')}: {element.count_searches}
                    </Typography>
                }
            >
                <ListItemAvatar>
                    <Avatar>
                        <PersonOutlineIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={element.name}
                    secondary={secondaryText}
                />
            </ListItem>
        );
    }

    return (
        <Container component="main" maxWidth="xl">
            <Grid item xs={12} md={12}>

                {message && (
                    <Stack sx={{width: '100%', marginTop: 4.4}} spacing={2}>
                        <Alert
                            onClose={handleCloseMessage}
                            severity={severity}>{message}</Alert>
                    </Stack>
                )}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5" component="div"
                                textAlign={"center"}>
                        {t('searchHistory')}
                    </Typography>

                    <Box sx={{width: '100%'}}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            <Step key="1">
                                <StepButton
                                    color="inherit" onClick={() => handleStep(0)}>
                                    {t('userList2')}
                                </StepButton>
                            </Step>
                            <Step key="2">
                                <StepButton
                                    color="inherit" onClick={() => handleStep(1)}>
                                    {t('userSearches')}
                                </StepButton>
                            </Step>
                            <Step key="3">
                                <StepButton color="inherit" onClick={() => handleStep(2)}>
                                    {t('searchResults')}
                                </StepButton>
                            </Step>
                        </Stepper>
                    </Box>
                    {loading && <SuperLoader/>}
                    {!searchOpened && !searchForUserOpened &&
                    <Demo>
                        <List dense={dense}>
                            <ListItem>
                                <ListItemText primary={t('showUsersWithNoSearches')}/>
                                <Switch
                                    edge="end"
                                    onChange={showHideNullSearches}
                                    checked={showUsersWithZeroSearches}
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-wifi',
                                        'aria-label': t('showUsersWithNoSearches')
                                    }}
                                />
                            </ListItem>
                            {searchHistoryList.map(searchElement)}
                        </List>
                    </Demo>
                    }
                    {searchForUserOpened &&
                    <History
                        url={searchForUser}
                        parent="searchHistory"
                        clickSecondStep={clickSecondStep}
                        setClickSecondStep={setClickSecondStep}
                        sendToParent={setActiveStep}
                    />
                    }
                    {searchOpened &&
                    <SearchResults
                        url={currentSearch}
                    />
                    }
                </Grid>
            </Grid>
        </Container>

    );
};

export default supportList;
