import * as React from 'react';
import {useTranslation} from 'react-i18next'
import {useState, useEffect} from "react";

import AuthService from "../services/auth.service";
import RateService from "../services/rate.service";

import SuperLoader from "../components/SuperLoader";
import BasicButton from "../components/buttons/BasicButton";

import Alert from "@mui/material/Alert";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RateTable from "../components/RateTable";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import Avatar from "@mui/material/Avatar";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from "@mui/material/Stack";
import StepButton from '@mui/material/StepButton';
import Typography from "@mui/material/Typography";

const theme = createTheme();

const Blah = styled('div')`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
`;

export default function BetaAccessRequest() {

    const {t} = useTranslation();
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [messageMarginTop, setMessageMarginTop] = useState(4);
    const [billingAddressIsTheSameWithOrganisationAddress, setBillingAddressIsTheSameWithOrganisationAddress] = useState(true);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [rateList, setRateList] = useState([]);
    const [featureList, setFeatureList] = useState([]);

    const [preSelectedRateForDialog, setPreSelectedRateForDialog] = useState(undefined);
    const [isPreSelectedRateForDialogCustom, setIsPreSelectedRateForDialogCustom] = useState(undefined);

    const [chosenRateId, setChosenRateId] = useState();

    const [clickedBackButtonOnStapper, setClickedBackButtonOnStapper] = useState(false);

    const [emptyEmail, setEmptyEmail] = useState(false);
    const [emptyName, setEmptyName] = useState(false);
    const [emptyCompanyName, setEmptyCompanyName] = useState(false);
    const [emptyCompanyAddress, setEmptyCompanyAddress] = useState(false);

    useEffect(() => {
        loadRateList();
    }, []);

    const loadRateList = async () => {
        setLoading(true);
        setMessage(null);
        setTimeout(() => {
            RateService.getFeatureList()
                .then(function (response) {
                    console.log(response.data);
                    setFeatureList(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    console.log("Some error...");
                })
            RateService.listTiers()
                .then(function (response) {
                    console.log(response.data);
                    setRateList(response?.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    console.log("Some error...");
                })
        }, "2000")
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setEmptyEmail(false);
        setEmptyName(false);
        setEmptyCompanyName(false);
        setEmptyCompanyAddress(false);

        if (!chosenRateId) {
            setMessage(t("selectRateOnPrevPage", "Bitte wählen Sie eine passende Tarifoption im vorherigen Schritt."));
            return;
        }

        const data = new FormData(event.currentTarget);

        let name = data.get('name');
        let email = data.get('email');
        let company_name = data.get('company_name');
        let company_address = data.get('company_address');
        let billing_address = billingAddressIsTheSameWithOrganisationAddress ? data.get('company_address') : data.get('billing_address');
        let coupon = data.get('coupon');

        if (email === "" || name === "" || company_name === "" || company_address === "") {

            if(email.trim() === "") setEmptyEmail(true);
            if(name.trim() === "") setEmptyName(true);
            if(company_name.trim() === "") setEmptyCompanyName(true);
            if(company_address.trim() === "") setEmptyCompanyAddress(true);

            setMessage(t("mandatoryFieldsError", "Bitte füllen Sie alle geforderten Felder aus."));
            return;
        }
        // eslint-disable-next-line no-console
        console.log({
            email: email,
            name: name,
            company_name: company_name,
            company_address: company_address,
            billing_address: billing_address,
            rate_id: chosenRateId,
            coupon: coupon,
        });

        setMessage(undefined);
        setLoading(true);

        AuthService.register(name, email, company_name, company_address, billing_address, chosenRateId, coupon)
            .then(function (response) {
                if (response.request.status == 201) {
                    setMessage(t('BetaAccessPartMessage1') + response.data.name + t('BetaAccessPartMessage2') + response.data.email + t('BetaAccessPartMessage3'));
                    setSeverity("success");
                }
                setMessageMarginTop(2);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error.response.data);

                if (error.response.data.email[0] === "custify user with this email already exists.") {
                    setMessage(t("userExists", "Ein Benutzer mit dieser E Mail Adresse existiert bereits."));
                } else {
                    setMessage(t("mandatoryFields", "Bitte füllen Sie alle geforderten Felder aus."));
                }
                setSeverity("error");
                setLoading(false);
            });
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
        setMessageMarginTop(4);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const switchBillingAddressCheckbox = (event) => {
        console.log(event.target.checked);
        setBillingAddressIsTheSameWithOrganisationAddress(event.target.checked);
    }

    const handleStep = (index) => {
        if (index === 0 && chosenRateId) {
            setClickedBackButtonOnStapper(true);
        }
        if (index === 1) {
            setStep(index);
            setClickedBackButtonOnStapper(false);
        }
        setMessage(undefined);
    }

    const selectRate = async () => {
        handleCloseDialog();
        setStep(1);
        setChosenRateId(preSelectedRateForDialog);
    }

    const handleClickSelectRateButton = (id, is_custom, name) => {
        console.log(name);
        setPreSelectedRateForDialog(id);
        setIsPreSelectedRateForDialogCustom(is_custom);
        if (name.toLowerCase() === "premium") {
            setIsPreSelectedRateForDialogCustom(true);
        }
        setOpenDialog(true);
    };

    const dialogButtonAcceptClick = async () => {
        setChosenRateId(preSelectedRateForDialog);
        setStep(1);
        setClickedBackButtonOnStapper(false);
        handleCloseDialog();
    }

    const dialogButtonCancelClick = () => {
        setPreSelectedRateForDialog(undefined);
        setIsPreSelectedRateForDialogCustom(false);

        // setClickedBackButtonOnStapper(false);
        handleCloseDialog();
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main">
                <CssBaseline/>
                {message && (
                    <Stack sx={{width: '100%', marginTop: 5}} spacing={2}>
                        <Alert
                            onClose={handleCloseAlert}
                            severity={severity}>{message}</Alert>
                    </Stack>
                )}
                <Box
                    sx={{
                        marginTop: messageMarginTop,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stepper activeStep={step} alternativeLabel>
                        <Step key="1"
                              sx={{
                                  '& .MuiStepLabel-root .Mui-completed': {
                                      color: '#02615D', // circle color (COMPLETED)
                                  },
                                  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                      {
                                          color: '#02615D', // Just text label (COMPLETED)
                                      },
                                  '& .MuiStepLabel-root .Mui-active': {
                                      color: '#02615D', // circle color (ACTIVE)
                                  },
                                  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                      {
                                          color: '#02615D', // Just text label (ACTIVE)
                                      },
                              }}
                        >
                            <StepButton
                                color="inherit" onClick={() => handleStep(0)}>
                                {t('selectYourRate', 'Auswahl Lizenz')}
                            </StepButton>
                        </Step>
                        <Step key="2"
                              sx={{
                                  '& .MuiStepLabel-root .Mui-completed': {
                                      color: '#02615D;', // circle color (COMPLETED)
                                  },
                                  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                      {
                                          color: '#02615D', // Just text label (COMPLETED)
                                      },
                                  '& .MuiStepLabel-root .Mui-active': {
                                      color: '#02615D', // circle color (ACTIVE)
                                  },
                                  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                      {
                                          color: '#02615D', // Just text label (ACTIVE)
                                      },
                              }}
                        >
                            <StepButton
                                color="inherit" onClick={() => handleStep(1)}>
                                {t('enterCompanyData', 'Eingabe Kontaktdaten')}
                            </StepButton>
                        </Step>
                    </Stepper>

                    {((step === 0 && !loading) || (step === 1 && !loading && chosenRateId && clickedBackButtonOnStapper)) &&
                    <>
                        <RateTable
                            disableLogo
                            openDialog={openDialog}
                            rateList={rateList}
                            featureList={featureList}
                            handleClickSelectRateButton={handleClickSelectRateButton}
                            isPreSelectedRateForDialogCustom={isPreSelectedRateForDialogCustom}
                            dialogButtonAcceptClick={dialogButtonAcceptClick}
                            dialogButtonCancelClick={dialogButtonCancelClick}
                            chosenRateId={chosenRateId}
                        />
                        <Typography
                            sx={{
                                mt: 3.4, mb: 3,
                                margin: "auto",
                                maxWidth: "70%"
                            }}
                            variant="h6" component="div"
                            textAlign={"center"}>
                            {t('ratePlanListUserSubHeader', "Ein transparentes Angebot - ohne versteckte Kosten. " +
                                "Wählen Sie das für sich passende Angebot, monatlich kündbar. Bei Fragen stehen wir Ihnen gerne zu Verfügung.")
                            }
                        </Typography>
                    </>
                    }
                    {loading &&
                    <SuperLoader/>
                    }

                    {step === 1 && !clickedBackButtonOnStapper &&
                    <>
                        <Blah>
                            <Avatar

                                sx={{m: 1, bgcolor: '#02615D', mt: 1.5}}
                            >
                                <PersonAddAltIcon/>
                            </Avatar>
                        </Blah>
                        <Typography
                            sx={{
                                mt: 1, mb: 2,
                                fontWeight: "bold",
                                color: "#02615D"
                            }}
                            variant="h5" component="div"
                            textAlign={"center"}>
                            {t('betaAccessPageHeader1', 'Eingabe Kontaktdaten')}
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <TextField
                                required
                                error={emptyName}
                                margin="normal"
                                fullWidth
                                name="name"
                                label={t('firstAndLastName')}
                                type="text"
                                id="name"
                            />
                            <TextField
                                required
                                error={emptyCompanyName}
                                margin="normal"
                                fullWidth
                                id="company_name"
                                label={t('companyName', 'Name der Firma')}
                                name="company_name"
                                autoComplete="company name"
                                autoFocus
                            />
                            <TextField
                                required
                                error={emptyCompanyAddress}
                                margin="normal"
                                fullWidth
                                id="company_address"
                                label={t('companyAddress', 'Firmenanschrift')}
                                name="company_address"
                                autoComplete="company address"
                                autoFocus
                            />
                            <TextField
                                required
                                error={emptyEmail}
                                margin="normal"
                                fullWidth
                                id="email"
                                label={t('emailAddress')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                // error={emptyEmail}
                                margin="normal"
                                fullWidth
                                id="coupon"
                                label={t('Gutschein')}
                                name="coupon"
                                autoComplete="coupon"
                                autoFocus
                            />
                            <strong>Rechnungsadresse: &nbsp;</strong><FormControlLabel control={
                            <Checkbox
                                onChange={switchBillingAddressCheckbox}
                                checked={billingAddressIsTheSameWithOrganisationAddress}
                            />}
                                                                                       label={t("registerCheckbox", "Entspricht den eingegebenen Kontaktdaten")}/>
                            {!billingAddressIsTheSameWithOrganisationAddress &&
                            <>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="billing_address"
                                    label={t('billingAddress')}
                                    name="billing_address"
                                    autoComplete="billingAddress"
                                    autoFocus
                                />
                            </>
                            }
                            {!loading &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <BasicButton
                                    size="large"
                                    startIcon={<PersonAddAltIcon/>}
                                    type="submit"

                                    variant="contained"
                                    text={t('requestAccess')}
                                />
                            </Box>
                            }
                        </Box>
                    </>
                    }
                </Box>
            </Container>
        </ThemeProvider>
    );
}