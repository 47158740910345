import * as React from 'react';
import {useTranslation} from "react-i18next";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import CardActions from "@mui/material/CardActions";
import ToggleButton from "@mui/material/ToggleButton";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import {styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function TierPlanTile(props) {

    const {t} = useTranslation();
    const {
        data,
        me,
        currentUserRate
    } = props;

    const renderAction = () => {
        if(!me.is_admin) return false;
        return (
            <ToggleButtonGroup
                color="primary"
                exclusive
            >
                <Tooltip title={t('tierPlanEdit', "edit")}>
                    <ToggleButton
                        onClick={props.updateTier}
                        sx={{
                            color: "#02615D",
                            border: 0,
                            paddingTop: "6px",
                            paddingLeft: "2px"
                        }}
                        value="android">
                        <EditIcon/>
                    </ToggleButton>
                </Tooltip>
            </ToggleButtonGroup>
        );

    }

    return (
        <Card sx={{
            textAlign: "justify",
            borderRadius: 0,
            borderBottom: (currentUserRate && currentUserRate.rate === data.id) ? 3 : 3,
            borderRight: (currentUserRate && currentUserRate.rate === data.id) ? 3 : 3,
            borderLeft: (currentUserRate && currentUserRate.rate === data.id) ? 3 : 3,
            borderTop: (currentUserRate && currentUserRate.rate === data.id) ? 3 : 3,
            borderColor: (currentUserRate && currentUserRate.rate === data.id) ? "#02615D" : "white"
        }}>
            <CardHeader
                sx={{
                    backgroundColor: "#EFEEF0",
                    color: "#02615D",
                    padding: 0,
                    paddingLeft: "9px",
                    paddingTop: "4px",
                }}
                action={renderAction()}
                title={data.name}
            />

            <CardContent
                sx={{
                    backgroundColor: "white"
                }}
            >
                {!data.is_active &&
                <Typography variant="body1">
                    <strong>{t("deactivated", "Deaktiviert!")}</strong>
                </Typography>
                }
                <br />

                <Typography variant="body1">
                    <strong>{data.price_euro + '€/' + data.period_months + " " + t('month', 'Monat(e)')}</strong>
                </Typography>

                <br/>
                <Typography> <b>{t("features","Bestandteile")}:</b></Typography>

                {data.features.map((value, index) => {
                         return (
                             <Typography> <b>{value.name}</b>: {value.description}</Typography>
                         );
                     })}

            </CardContent>
            <CardActions
                sx={{
                    backgroundColor: "white",
                    height: "50px"
                }}
            >

                <ExpandMore
                    sx={{
                        padding: "0",
                        '&:hover': {
                            borderRadius: "0px",
                            padding: "0",
                            backgroundColor: '#8a0856',
                        },
                    }}
                >
                    {currentUserRate?.rate !== data.id &&
                    <Button
                        onClick={props.selectPlan}
                        size="small"
                        sx={{
                            padding: "6px 10px 3px 10px",
                            borderRadius: "0",
                            backgroundColor: "#02615D",
                            color: "white",
                            textTransform: "none",
                            '&:hover': {
                                // backgroundColor: '#8a0856',
                                backgroundColor: '#9FD0C7',
                            },
                        }}
                    >{t('selectRate', "auswählen")}</Button>
                    }
                    {(currentUserRate && currentUserRate.rate === data.id) &&
                    <Button
                        // onClick={props.bookmarkAdd}
                        size="small"
                        disabled={true}
                        sx={{
                            padding: "6px 10px 3px 10px",
                            borderRadius: "0",
                            backgroundColor: '#9FD0C7',
                            color: "white",
                            textTransform: "none",
                            '&:hover': {
                                backgroundColor: '#8a0856',
                                // backgroundColor: '#9FD0C7',
                            },
                        }}
                    >{t('selectedRate', "Gewählt")}</Button>
                    }
                </ExpandMore>
            </CardActions>
        </Card>
    )
}
