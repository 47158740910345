import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_SUPPORT = API_URL + "/support/tickets/";
const API_URL_USER_TICKETS = API_URL + "/support/users/";

// Add a request interceptor
const axios_search = axios.create();
axios_search.interceptors.request.use(function (config) {
    let token = AuthService.getCurrentUser();
    config.headers.Authorization = 'JWT ' + token.access;
    return config;
});

const createSupportTicket = (ticketData) => {
    let token = AuthService.getCurrentUser();
    return axios_search.post(API_URL_SUPPORT,
        {
            "title": ticketData
        },
    );
};

const ticketDetail = (url) => {
    let token = AuthService.getCurrentUser();
    return axios_search.get(url, {
        headers: {
            'Authorization': 'JWT ' + token.access,
        }
    });
};
// const updateSearchSelectedTaxNumber = (url) => {
//     return axios_search.put(API_URL + url);
// };
// const listUsersAndSearchMetadata = () => {
//     return axios_search.get(API_URL_SEARCH_USERS);
// };
const listTicketsForUser = (userId, url) => {
    // console.log(userId, url);
    if (url) {
        return axios_search.get(API_URL + url);
    }
    return axios_search.get(API_URL_USER_TICKETS + userId);
};

export default {
    createSupportTicket,
    listTicketsForUser,
    // listTicketsForUser,
    // numberOfTicketsPerUser,
    ticketDetail,
    // supportMessageCreate,
    // listMessagesForTicket,
};
