import React from "react";
import Moment from 'moment';
import {useTranslation} from 'react-i18next'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";

import AuthService from "../services/auth.service";
import SearchService from  "../services/search.service";
import RateService from "../services/rate.service";
import ErrorHandler from "../services/error.handler";

import Invoice from "../components/Invoice";
import SuperLoader from "../components/SuperLoader";
import EnhancedTable from "../components/EnhancedTable";

import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Alert from "@mui/material/Alert";
import Pagination from '@mui/material/Pagination';
import {backgroundMain} from "../components/Colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

const Invoices = (props) => {
    const {
        me,
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [dense, setDense] = useState(true);

    const [searchHistoryList, setSearchHistoryList] = useState([]);
    const [currentSearch, setCurrentSearch] = useState("");
    const [searchOpened, setSearchOpened] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const [invoiceNumber, setInvoiceNumber] = useState(searchParams.get("invoice_id"))
    const [numberOfPages, setNumberOfPages] = useState(1);


    const headCells = [
        {
            id: 'rateName',
            numeric: false,
            label: 'Tarifname',
        },
        {
            id: 'name',
            numeric: true,
            label: 'Benutzername',
        },
        {
            id: 'Company',
            numeric: true,
            label: 'Organisation',
        },
        {
            id: 'created_on',
            numeric: true,
            label: t("invoiceDate", "Rechnungsdatum"),
        },
    ];

    const Demo = styled('div')(({theme}) => ({
        backgroundColor: theme.palette.background.paper,
        // backgroundColor: "red",
    }));

    const navigate = useNavigate();

    useEffect(() => {
        loadInvoiceHistory();
    }, []);

    const loadInvoiceHistory = async () => {
        if (invoiceNumber) {
            setSearchOpened(true);
            return;
        }
        setLoading(true);
        AuthService.getCurrentUserData()
            .then(function (response) {
                RateService.invoiceList(response.data.id, props.url)
                    .then(function (response) {
                        console.log(response.data);
                        setSearchHistoryList(response?.data);
                        // setNumberOfPages(Math.floor(response.data.count / 10) + 1);
                        setLoading(false);
                    })
                    .catch((error) => {
                        // expired access token
                        ErrorHandler.handleError(error);
                        console.log("Access token has expired. Refreshing...");
                        if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                            AuthService.refreshAccessToken()
                                .then(() => {
                                    loadInvoiceHistory();
                                })
                                .catch((error) => {
                                    // expired refresh token => logout
                                    ErrorHandler.handleError(error);
                                    console.log("Refresh token has expired. Logout...");
                                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                        AuthService.logout();
                                        // navigate('/');
                                        window.location.reload();
                                    }
                                });
                        } else if (error.response.status === 403) {
                            navigate('/');
                            window.location.reload();
                        }
                    })
            })
            .catch((error) => {
                ErrorHandler.handleError(error);
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            loadInvoiceHistory();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            ErrorHandler.handleError(error);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
    };

    function searchElement(element, index) {
        return (
            <ListItem
                sx={{
                    "&:hover": {
                        backgroundColor: "#f6f6f6",
                        cursor: "pointer"
                    }
                }}
                onClick={() => openSearchComponent(element.pk)}
                secondaryAction={
                    <Typography>
                        {element.rateuser.user.company_name} &nbsp; {t("invoiceDate", "Rechnungsdatum:")} {Moment(element.created_on).format('D.MM.YYYY')}
                    </Typography>
                }
            >
                <ListItemAvatar>
                    <Avatar><LocalMallIcon/></Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={element.rateuser.rate.name ? element.rateuser.rate.name : "..."}
                    secondary={me.is_admin ? t("userName", "Benutzername: ") + element.rateuser.user.name : null}
                />
            </ListItem>
        );
    }

    const openSearchComponent = (invoice_id) => {
        console.log("Invoice id: " + invoice_id);
        // setLoading(true);
        setSearchOpened(true);
        setInvoiceNumber(invoice_id);


        setActiveStep(1);
        if (props.setClickSecondStep) {
            props.setClickSecondStep(false);
        }
    }

    const handleStep = (index) => {
        if (index === 0) {
            setSearchOpened(false);
            setCurrentSearch("");
            setActiveStep(0);
            setInvoiceNumber(null);
            // loadInvoiceHistory();
            navigate('/invoices');
            window.location.reload();
        }
    }

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
    };

    const changePage = (event, value) => {
        console.log(props.url);
        AuthService.getCurrentUserData()
            .then(function (response) {
                SearchService.listSearchesForUser(response.data.id, null, "?page=" + value)
                    .then(function (response) {
                        console.log(response.data);
                        setSearchHistoryList(response?.data);
                        // setNumberOfPages(Math.floor(response.data.count / 10) + 1);
                        setLoading(false);
                    })
                    .catch((error) => {
                        // expired access token
                        ErrorHandler.handleError(error);
                        console.log("Access token has expired. Refreshing...");
                        if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                            AuthService.refreshAccessToken()
                                .then(() => {
                                    loadInvoiceHistory();
                                })
                                .catch((error) => {
                                    // expired refresh token => logout
                                    ErrorHandler.handleError(error);
                                    console.log("Refresh token has expired. Logout...");
                                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                        AuthService.logout();
                                        // navigate('/');
                                        window.location.reload();
                                    }
                                });
                        } else if (error.response.status === 403) {
                            navigate('/');
                            window.location.reload();
                        }
                    })
            })
            .catch((error) => {
                ErrorHandler.handleError(error);
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            loadInvoiceHistory();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            ErrorHandler.handleError(error);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })

    }

    return (
        <Container component="main" maxWidth="xl">
            <Grid item xs={12} md={12}>

                {message && (
                    <Stack sx={{width: '100%', marginTop: 4.4}} spacing={2}>
                        <Alert
                            onClose={handleCloseMessage}
                            severity={severity}>{message}</Alert>
                    </Stack>
                )}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    {!props.parent &&
                    <Typography sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5" component="div"
                                textAlign={"center"}>
                        <CorporateFareIcon
                            sx={{
                                marginBottom: "-5px",
                                fontSize: "30px",
                                paddingRight: "4px"
                            }}
                        />
                        {t('invoiceList', "Rechnungen")}:
                    </Typography>
                    }

                    {!props.parent && !loading && searchOpened &&
                    <Typography sx={{marginLeft: "30px", cursor: "pointer"}}
                                onClick={() => handleStep(0)}><ListAltIcon/><KeyboardBackspaceIcon/></Typography>
                    }

                    {loading && <SuperLoader/>}
                    {(!searchOpened || props.clickSecondStep) &&
                    <Demo>
                        {me.is_admin &&
                        <EnhancedTable
                            headerData={headCells}
                            tableData={searchHistoryList}
                            openSearchComponent={openSearchComponent}
                        />
                        }

                        {!me.is_admin &&
                        <List dense={dense}
                              sx={{
                                  backgroundColor: backgroundMain,
                              }}
                        >
                            {searchHistoryList.map(searchElement)}
                        </List>
                        }

                    </Demo>
                    }



                    {searchOpened && invoiceNumber &&
                    <Invoice
                        me={me}
                        invoiceNumber={invoiceNumber}
                    />
                    }

                    {!searchOpened &&
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {!me.is_admin &&
                        <Grid item xs={5}>
                            <Pagination
                                onChange={changePage}
                                sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5" component="div"
                                count={numberOfPages}/>
                        </Grid>
                        }
                    </Grid>
                    }
                </Grid>
            </Grid>

        </Container>

    );
};

export default Invoices;
