import React from 'react';
import Loader from 'react-loader-spinner';
import Box from "@mui/material/Box";

export default function SuperLoader() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Loader
                type="ThreeDots" color="#02615D" height={80} width={80}/>
        </Box>
    )
}