import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom"
import {useTranslation} from 'react-i18next'

import BasicButton from "../components/buttons/BasicButton";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CssBaseline from "@mui/material/CssBaseline";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from "@mui/material/Typography";
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircleIcon from '@mui/icons-material/Circle';
import {TableFooter} from "@mui/material";
import DialogContentText from '@mui/material/DialogContentText';
import Avatar from "@mui/material/Avatar";
import DoneIcon from '@mui/icons-material/Done';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const DatenschutzLink = styled(Link)`
  margin-right: 6px;
  margin-left: 6px;
  color: #7e8c96 !important;
  text-decoration: none;
  display: inline;
  &:hover {
    color: #7e8c96;
  }
`;

const Blah = styled('div')`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
`;

const RateTable = (props) => {

    const {t} = useTranslation();

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            fontWeight: 'bold',
            fontSize: 18,
            borderBottom: "none",
            paddingTop: "15px",
            paddingBottom: "15px"
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderBottom: "none",
            paddingTop: "10px",
            paddingBottom: "10px"
        },
        [`&.${tableCellClasses.footer}`]: {
            fontSize: 18,
            borderBottom: "none",
            color: theme.palette.common.white,
            paddingTop: "5px",
            paddingBottom: "5px"
        },
    }));
    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const {
        disableLogo,
        openDialog,
        rateList,
        featureList,
        handleClickSelectRateButton,
        isPreSelectedRateForDialogCustom,
        dialogButtonAcceptClick,
        dialogButtonCancelClick,

        chosenRateId,
    } = props;

    const selectPlan = async (rate_id) => {

    }

    const renderFeature = (array, currentFeatureId) => {

        let isCurrentFeatureInRate = false;
        array.map((value, index) => {
            if (currentFeatureId === value.id) {
                isCurrentFeatureInRate = true;
            }
        });

        if (isCurrentFeatureInRate) {
            return (
                <CircleIcon sx={{color: '#02615d'}}/>
            );
        } else {
            return (
                <CircleIcon color="disabled"/>
            )
        }
    }

    const renderRateTableForUser = () => {

        return (
            <TableContainer>
                <Table sx={{minWidth: '700'}} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t("features","Bestandteile")}</StyledTableCell>
                            {rateList.map((value, index) => {
                                return (
                                    <StyledTableCell sx={{textTransform: 'uppercase'}}
                                                     align="center">{value.name}</StyledTableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {featureList.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                                {rateList.map((value, index) => {
                                    return (
                                        <StyledTableCell
                                            align="center"
                                            sx={{paddingLeft: '32px'}}>

                                            {renderFeature(value.features, row.id)}

                                        </StyledTableCell>

                                    );
                                })}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow sx={{
                            background: '#8b8689',
                        }}>
                            <StyledTableCell></StyledTableCell>
                            {rateList.map((value, index) => {
                                return (
                                    <StyledTableCell
                                        sx={{
                                        padding: "7px"
                                    }}
                                        align="center">
                                        {value.is_custom ?
                                            <Typography>
                                                <strong>auf Anfrage</strong>
                                            </Typography>
                                            :
                                            <>
                                                <Typography
                                                    variant="h6" component="div">
                                                    <strong>{value.price_euro} &#x20AC;</strong>
                                                </Typography>
                                                <Typography>
                                                    <strong>pro Monat</strong>
                                                </Typography>
                                            </>

                                        }
                                    </StyledTableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow sx={{background: 'white'}}>
                            <StyledTableCell></StyledTableCell>
                            {rateList.map((value, index) => {
                                return (
                                    <StyledTableCell
                                        align="center"
                                        sx={{
                                            paddingTop: "0px",
                                            paddingBottom: "10px",
                                        }}>
                                        <BasicButton
                                            noMargin
                                            onClick={() => {
                                                handleClickSelectRateButton(value.id, value.is_custom, value.name)
                                            }}
                                            size="small"
                                            disabled={(chosenRateId && chosenRateId === value.id)}
                                            startIcon={(chosenRateId && chosenRateId === value.id)
                                                ? <CheckBoxIcon/>
                                                : <CheckBoxOutlineBlankIcon/>
                                            }
                                            text={(chosenRateId && chosenRateId === value.id) ? t('selectedRate', 'Gewählt') : (value.is_custom) ? 'Anfordern' : t('selectRate', 'Auswählen')}
                                        />

                                    </StyledTableCell>
                                );
                            })}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        );
    }

    const renderHeader = () => {

        return (
            <>
                {!disableLogo &&
                <Blah>
                    <Avatar

                        sx={{m: 1, bgcolor: '#02615D', mt: 1.5}}
                    >
                        <PersonAddAltIcon/>
                    </Avatar>

                </Blah>
                }

                <Typography
                    sx={{
                        mt: 1, mb: 2,
                        fontWeight: "bold",
                        color: "#02615D"
                    }}
                    variant="h5" component="div"
                    textAlign={"center"}>
                    {t('ratePlanWhenRegisterHeader', "Auswahl Lizenz")}
                </Typography>
            </>
        )

    }

    const RateDialog = () => {

        const [isPrivacyCheckboxChecked, setIsPrivacyCheckboxChecked] = useState(false);
        const [isDataprivacyCheckboxChecked, setIsDataprivacyCheckboxChecked] = useState(false);
        const [dataprivacyMessage, setDataprivacyMessage] = useState();
        const [privacyMessage, setPrivacyMessage] = useState();

        const dataPrivacyCheckboxChenge = (event) => {
            setDataprivacyMessage(null);
            setIsDataprivacyCheckboxChecked(event.target.checked);
        }

        const privacyCheckboxChenge = (event) => {
            setPrivacyMessage(null);
            setIsPrivacyCheckboxChecked(event.target.checked);
        }

        const selectRateButtonClick = () => {
            let error = false;
            if (!isDataprivacyCheckboxChecked) {
                setDataprivacyMessage(t("datePrivacyMessage", " Ich bestätige hiermit die AGB."));
                error = true;

            }
            if (!isPrivacyCheckboxChecked) {
                setPrivacyMessage(t("privacyMessage", "Ich bestätige hiermit die Datenschutzbestimmungen."));
                error = true;
                return;
            }
            if (!error) dialogButtonAcceptClick();
        }

        return (
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: 0,
                        paddingRight: "15px",
                        paddingTop: "15px",
                        paddingLeft: "10px"
                    }
                }}
                open={openDialog}
                keepMounted
                // onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {isPreSelectedRateForDialogCustom &&
                        <strong>
                            {t('thanksForYourInterest', 'Bitte kontaktieren' +
                                ' Sie uns bei Interesse unter custify@cologne-customs-consulting.de')}
                        </strong>
                        }
                        {!isPreSelectedRateForDialogCustom &&
                        <strong>
                            {t('confirmDataPrivacyFirst', 'Bitte bestätigen Sie den Datenschutzbestimmungen und Datenschutzerklärung:')}
                        </strong>
                        }
                        <br/>
                        {!isPreSelectedRateForDialogCustom &&
                        <>
                            <FormControlLabel control={
                                <Checkbox
                                    onChange={dataPrivacyCheckboxChenge}
                                    checked={isDataprivacyCheckboxChecked}/>}
                                              label={t("datePrivacyAccept", "Ich bestätige hiermit die ")}/>
                            <DatenschutzLink to={'/AGB.pdf'}
                                             target="_blank">{t("privacypolicy", "AGB. ")}</DatenschutzLink>
                            {dataprivacyMessage && <div style={{color: 'red'}}>{dataprivacyMessage}</div>}
                            <br/>

                            <FormControlLabel control={
                                <Checkbox
                                    onChange={privacyCheckboxChenge}
                                    checked={isPrivacyCheckboxChecked}/>}
                                              label={t("datePrivacyAccept", "Ich bestätige hiermit die ")}/>
                            <DatenschutzLink to={'/Datenschutz_CCC_v2_am_24082022.pdf'}
                                             target="_blank">{t("dataprivacy", "Datenschutzbestimmungen. ")}</DatenschutzLink>
                            {privacyMessage && <div style={{color: 'red'}}>{privacyMessage}</div>}
                        </>
                        }
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <BasicButton
                        noMargin
                        onClick={dialogButtonCancelClick}
                        type={"close"}
                        startIcon={<HighlightOffIcon/>}
                        text={t("close", "Schließen")}
                    />
                    {!isPreSelectedRateForDialogCustom &&
                    <BasicButton
                        noMargin
                        onClick={selectRateButtonClick}
                        startIcon={<DoneIcon/>}
                        text={t("accept", "Akzeptieren")}
                    />
                    }
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <RateDialog/>
            <Container
                maxWidth="xl"
                component="main">
                <CssBaseline/>

                {renderHeader()}

                <Container
                    maxWidth="xl"
                    component="main">
                    <CssBaseline/>
                    {rateList && rateList && renderRateTableForUser()}
                </Container>
            </Container>
        </>
    );
};

export default RateTable;
