import * as React from 'react';
import {useTranslation} from "react-i18next";

import RoundedButton from "../components/buttons/RoundedButton";

import jsPDF from "jspdf";
import "jspdf-autotable";

import {styled} from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarRateIcon from '@mui/icons-material/StarRate';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AuthService from "../services/auth.service";
import Moment from "moment";

const SearchCard = styled('div')`
                    width: 100%;
                    background-color: #FFF;
                    position: relative;
                    border-radius: 40px;
                    overflow: hidden;
                    // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;

const TextContainer = styled('div')`
                    padding: 0 0 10px 0;
`;

const SearchCardHeader = styled('div')`
                    padding: 2px 0 0 20px;
                    background-color: #e3e1e6;
                    color: #02615D;
                    font-weight: bold;
`;

const SearchCardContent = styled('div')((props) => ({
    padding: "8px 15px 10px 15px",
    minHeight: !props.list ? "calc(13vh)" : "",
    // minHeight: !props.list ? "120%" : "",
    marginBottom: !props.list ? "55px" : "48px"

}));

const SearchCardFooter = styled('div')`
    display: flex;
    padding: 0 15px 10px 15px;
    position:relative;
    justify-content: space-between;
    // background-color: red;
    // position:absolute;
    // bottom:0;
`;

const ButtonToBottom = styled('div')((props) => ({
    position: !props.list ? "absolute" : "relative",
    bottom: !props.list ? 0 : "",
    width: "100%"

}));

const ButtonCombiner = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export default function TileMasonryRounded(props) {

    console.log(props.data);

    const {t} = useTranslation();
    const {
        data,
        list,
    } = props;

    const isBookmarked = () => {
        if (data.is_bookmarked_by_user || props.isBookmark) {
            return true;
        }
        return false;
    }

    const splitNumber = (number) => {
        return number.substring(0, 3) + " " + number.substring(3, 6) + " " + number.substring(6, 9) + " " + number.substring(9);
    }

    const renderTree = (nodes, doc, xPos, yPos) => {



        if (nodes.name !== 'root') {

            let addition = 0;

            if(yPos > 260) {
                yPos = 15;
                doc.addPage();
            }

            if (doc.splitTextToSize(nodes.value, 140).length > 1) {
                doc.text(nodes.name + ' - ', xPos, yPos);
                doc.splitTextToSize(nodes.value, 140).forEach((part) => {
                    doc.text(part, xPos + 30, yPos + addition);
                    addition += 7;
                })
                addition -= 7;
            } else doc.text(nodes.name + ' - ' + nodes.value, xPos, yPos);

            if (Array.isArray(nodes.children)) {
                nodes.children.map((node) => renderTree(node, doc, xPos, yPos + 7 + addition))
            }
        } else {
            if (Array.isArray(nodes.children)) {
                nodes.children.map((node) => renderTree(node, doc, xPos, yPos));
            }
        }
    }

    const downloadPdf = (data) => {

        AuthService.getCurrentUserData()
            .then(function (response) {
                // handle success
                // console.log(response.data);

                const doc = new jsPDF();
                let pageSize = 500 // Height position of new content

                let y = 56;
                // doc.setFont("arial", "bold");
                doc.setFontSize(12);

                const img = new Image();
                img.src = '../images/logo_custify.png';

                doc.addImage(img, 'png', 130, 10, 60, 60)

                doc.text(response.data.company_name, 14, y);
                doc.text(response.data.email, 14, y + 10);

                y += 25;
                doc.text("Zeitstempel der Speicherung:", 14, y);

                y += 7;
                doc.text(Moment(Date.now()).format('D.MM.YYYY') + " | " + Moment(Date.now()).format('H:mm:ss'), 14, y);

                y += 13;
                doc.text("ZTN:  " + data.tax_number, 14, y);

                y += 13;
                if (doc.splitTextToSize(data.description, 140).length > 1) {
                    doc.text("Suchbegriff: ", 14, y);
                    y += 7;
                    doc.splitTextToSize(data.description, 140).forEach((part) => {
                        doc.text(part, 14, y);
                        y += 7;
                    })
                } else doc.text("Suchbegriff: " + data.description, 14, y);

                y += 13;
                if (data.bold_words && data.bold_words.length > 0) {
                    doc.text(t('Schlagwörter: ') + data.bold_words, 14, y);
                } else doc.text(t('Schlagwörter: ') + "...", 14, y);

                if (data.tax_value !== null) {
                    y += 13;
                    doc.text(t('tax') + ": " + data.tax_value + "%", 14, y);
                }

                if (data.drittlandszollsatz !== null) {
                    y += 7;
                    doc.text(t('ThirdCountryDuty') + ": " + data.drittlandszollsatz.toString().replace('.', ',') + "%", 14, y);
                }

                y += 13;
                doc.text('Kategorie-Baum:', 14, y);

                y += 7;
                if (data.tree_path !== null) {
                    renderTree(data.tree_path, doc, 14, y);
                }

                doc.save(`Report_${data.tax_number}.pdf`);

            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <SearchCard id={"pdf"}>
            <SearchCardHeader>
                <span>{splitNumber(data.tax_number)}</span>
                <span>
                    <ToggleButton
                        onClick={() => {
                            navigator.clipboard.writeText(data.tax_number)
                        }}
                        sx={{
                            color: "#02615D",
                            border: 0,
                            marginLeft: "5px",
                            paddingLeft: "5px",
                            paddingRight: "3px",
                        }}
                        value="android">
                        <ContentCopyIcon/>
                    </ToggleButton>
                </span>
                <span>
                    <ToggleButton
                        onClick={() => downloadPdf(data)}
                        sx={{
                            color: "#02615D",
                            border: 0,
                            paddingLeft: "2px",
                            paddingRight: "3px"
                        }}
                        value="android">
                        <PictureAsPdfIcon/>
                    </ToggleButton>
                </span>
                <span>
                    {(data.expected_relevancy_score && data.expected_relevancy_score !== null) &&
                    <ToggleButton
                        onClick={() => {
                            navigator.clipboard.writeText(data.tax_number)
                        }}
                        sx={{
                            marginRight: "15px",
                            color: "#02615D",
                            border: 0,
                            float: "right"
                        }}
                        value="android">
                        <CrisisAlertIcon
                            style={{
                                color: (data.expected_relevancy_score < 0.4) ? 'red' : (data.expected_relevancy_score >= 0.5) ? 'green' : '#FFCD01'
                            }}
                        />
                        <span
                            style={{
                                color: (data.expected_relevancy_score < 0.4) ? 'red' : (data.expected_relevancy_score >= 0.5) ? 'green' : '#FFCD01'
                            }}
                        >&nbsp;{(data.expected_relevancy_score < 0.4) ? 'niedrig' : (data.expected_relevancy_score >= 0.5) ? 'hoch' : 'mittel'}</span>
                    </ToggleButton>
                    }
                </span>
            </SearchCardHeader>
            <SearchCardContent
                className="content"
                list={list}
            >
                <TextContainer>
                    {data.matched_text ? (
                        <Typography variant="body2">
                            {props.renderBoldWords}
                        </Typography>
                    ) : (
                        <Typography variant="body2">
                            {data.description}
                        </Typography>
                    )
                    }
                </TextContainer>

                {data.tax_value &&
                <Typography variant="body1">
                    {t('tax')}: <strong>{data.tax_value}%</strong>
                </Typography>
                }
                {data.drittlandszollsatz !== null &&
                <Typography variant="body1">
                    {t('ThirdCountryDuty')}: <strong>{data.drittlandszollsatz.toString().replace('.', ',')}%</strong>
                </Typography>
                }
            </SearchCardContent>
            <ButtonToBottom>
                <SearchCardFooter>
                    {/*<ButtonToBottom>*/}
                    <ButtonCombiner>
                        <RoundedButton
                            type={"secondary"}
                            onClick={props.openCloseTreeView}
                            size="small"
                            text={"Kategorie-Baum"}/>
                        <RoundedButton
                            disabled={!data.vzta_documents.length > 0}
                            align={"left"}
                            type={"secondary"}
                            onClick={props.openCloseDocView}
                            size="small"
                            text={"VZTA"}/>
                    </ButtonCombiner>
                    <ButtonCombiner>
                        <RoundedButton
                            type={"secondary"}
                            onClick={props.openCloseExportControlView}
                            size="small"
                            text={"Export Kontrolle"}
                        />
                        <RoundedButton
                            icon={
                                isBookmarked() ? <StarRateIcon /> : <StarOutlineIcon />
                            }
                            type={
                                isBookmarked() ? "secondary" : "main"
                            }
                            onClick={isBookmarked() ? props.bookmarkDelete : props.bookmarkAdd}
                            size="small"
                            text={
                                isBookmarked() ? t("deleteBookmark", "Von Merkliste entfernen") : t("saveBookmark", "zur Merkliste hinzufügen")
                            }
                    />
                     
                       </ButtonCombiner>
                    {/*</ButtonToBottom>*/}
                </SearchCardFooter>
            </ButtonToBottom>
        </SearchCard>
    )
}
