import axios from "axios";
import AuthService from "./auth.service";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_USER_STATISTICS = API_URL + "/news/statsdownload/";
const API_URL_FEEDBACK_RESULTS = API_URL + "/feedback/surveydownload/";

// Add a request interceptor
const axios_search = axios.create();
axios_search.interceptors.request.use(function (config) {
  let token = AuthService.getCurrentUser();
  config.headers.Authorization = "JWT " + token.access;
  return config;
});

const getUserStatistics = () => {
  let token = AuthService.getCurrentUser();
  return axios.get(API_URL_USER_STATISTICS, {
    headers: {
      Authorization: "JWT " + token.access,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer', 
  });
};

const getFeedbackResults = () => {
  let token = AuthService.getCurrentUser();
  return axios.get(API_URL_FEEDBACK_RESULTS, {
    headers: {
      Authorization: "JWT " + token.access,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer', 
  });
};

export default {
    getUserStatistics,
    getFeedbackResults
};
